import React, { useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import AppContext from "services/context";
import routes from "routing/routes";
import { COLORS } from "utils/constants";
import Breadcrumbs from "./Breadcrumbs";

import { Header, Footer } from "./index";

export default function Layout({ children, isHome, className }) {
  const { globalData = {} } = useContext(AppContext);
  const location = useLocation();
  const path = location.pathname?.split("/") ?? [];
  const lastPath = path[path.length - 1];

  const dynamicTitle = useMemo(
    () =>
      routes.find((route) => route.path === location.pathname)?.breadcrumb ??
      lastPath,
    [location, lastPath]
  );

  return (
    <StyledWrapper className={className}>
      <Helmet>
        <title>
          {dynamicTitle}
          {globalData.bank_name ? ` - ${globalData.bank_name}` : ""}
        </title>
      </Helmet>
      <Header isHome={isHome} />
      <StyledMain isHome={isHome}>
        {isHome ? (
          children
        ) : (
          <Container fluid={isHome}>
            <StyledBreadcrumbsWrapper>
              <Breadcrumbs />
            </StyledBreadcrumbsWrapper>
            {children}
          </Container>
        )}
      </StyledMain>
      <Footer />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div``;
const StyledMain = styled.main`
  background-color: ${(props) => (props.isHome ? "initial" : COLORS.lightBlue)};
  padding-bottom: 3rem;
  overflow: hidden;
`;
const StyledBreadcrumbsWrapper = styled.div`
  padding: 1.25rem 0;
`;
