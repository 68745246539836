import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import authProvider from "services/api/authProvider";

export default function ProtectedRoute({ component, ...rest }) {
  const loggedIn = authProvider.checkAuth();

  const Component = component;
  return (
    <Route
      {...rest}
      render={() => {
        return loggedIn ? <Component /> : <Redirect to="/" />;
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
