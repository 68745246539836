import styled from "styled-components";
import { Button } from "components/ui";

const StyledSubmitButton = styled(Button)`
    margin-top: 1rem;
`;

export default {
    StyledSubmitButton,
};
