import styled from "styled-components";
import { Button } from "components/ui";
import { COLORS } from "utils/constants";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
  margin-bottom: 8px;
  padding: 0.375rem 1.5rem;
`;

const StyledForm = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;

  @media only screen and (max-width: 767px) {
    display: block;
    button.btn {
      width: 261px;
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 414px) {
    button.btn {
      width: 100%;
    }
  }

  .MuiFormControl-root {
    @media only screen and (max-width: 414px) {
      max-width: 100%;
    }
  }
  label {
    top: -10px;
    font-weight: 500;
    white-space: nowrap;
  }
`;

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    display: block;
    margin-top: 0;
  }

  .MuiInputBase-root {
    padding: 0 0.75rem;
  }
`;

export default {
  StyledContainer,
  StyledForm,
  StyledButton,
  StyledActions,
};
