import { Row } from "react-bootstrap";
import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledRow = styled(Row)`
  .col {    
      @media only screen and (max-width: 991px) {
        flex-basis: auto;
      }
  }
`;

export default { StyledRow };
