import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";

import { LOAN_TYPES, VALIDATION_TEXTS } from "utils/constants";
import dataProvider from "services/api/dataProvider";

import {
  Button,
  SelectInput,
  ErrorMessage,
  TextInput,
  Slider,
  Icon,
  Tooltip,
} from "components/ui";
import { generateNumbersInRange, optionsFromData } from "utils/misc";
import InfoIcon from "assets/icons/info.svg";

const schema = yup.object().shape({
  amount: yup.number().required(VALIDATION_TEXTS.required),
  interest_rate: yup.number().required(VALIDATION_TEXTS.required),
});

export default function LoanForm({ onSubmit, loading, className }) {
  const { data = [] } = useQuery("currencies", () =>
    dataProvider
      .getList("currencies")
      .then((res) => res?.data || [])
      .catch((err) => err)
  );
  const initialCurrency = data?.[0]?.currency;

  const interestRates = useMemo(() => generateNumbersInRange([1, 10], 0.5), []);
  const ratesOptions = useMemo(
    () =>
      interestRates.map((rate) => ({
        label: `${rate}%`,
        value: rate,
      })),
    [interestRates]
  );

  const sliderMarks = useMemo(() => generateNumbersInRange([6, 102], 6), []);
  const marks = useMemo(
    () =>
      sliderMarks.map((rate, index) => ({
        label: index % 4 === 0 ? rate : "",
        value: rate,
      })),
    [sliderMarks]
  );

  const getLoanFromValue = (val) => {
    const loan = LOAN_TYPES.find((loan) => loan.value === val);
    return loan;
  };
  const initialLoanType = useMemo(() => getLoanFromValue("car"), []);

  const formik = useFormik({
    initialValues: {
      type: "car",
      amount: initialLoanType.amount || "",
      interest_rate: initialLoanType.interestRate || "",
      currency: initialCurrency || "",
      duration_in_months: 60,
    },
    validationSchema: schema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleLoanTypeChange = (event) => {
    const { name, value } = event.target;
    const loanType = getLoanFromValue(value);

    formik.setValues({
      ...formik.values,
      [name]: value,
      amount: loanType.amount,
      interest_rate: loanType.interestRate,
      currency: loanType.currency,
    });
  };

  return (
    <StyledForm noValidate onSubmit={formik.handleSubmit} className={className}>
      <Form.Group>
        <Form.Row>
          <Col sm={4}>
            <SelectInput
              id="type"
              name="type"
              label={
                <>
                  Loan type
                  <Tooltip
                    value="Malesuada sed pellentesque 
                  parturient viverra urna viverra ut 
                  cursus ut. Proin tortor vel non cras 
                  viverra nulla elementum id."
                  >
                    <span>
                      <Icon src={InfoIcon} />
                    </span>
                  </Tooltip>
                </>
              }
              testIdentifier="loan_type_select_elem"
              value={formik.values.type}
              options={LOAN_TYPES}
              onChange={handleLoanTypeChange}
            />
          </Col>
          <Col sm={4}>
            <TextInput
              id="amount"
              type="number"
              label="Amount"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.errors.amount}
              step={100}
              required
            />
          </Col>
          <Col sm={2}>
            <SelectInput
              id="interest_rate"
              name="interest_rate"
              label="Interest rate"
              testIdentifier="loan_interest_select_elem"
              value={formik.values.interest_rate}
              options={ratesOptions}
              onChange={formik.handleChange}
              required
            />
            <ErrorMessage error={formik.errors.interest_rate} />
          </Col>
          <Col sm={2}>
            <SelectInput
              id="currency"
              name="currency"
              label="Currency"
              testIdentifier="loan_currency_select_elem"
              value={formik.values.currency}
              options={optionsFromData(data, "currency", "currency")}
              onChange={formik.handleChange}
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Col>
            <Slider
              label={
                <p>
                  Choose period <span>(months)</span>
                </p>
              }
              //getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={6}
              min={6}
              max={102}
              marks={marks}
              id="slider"
              name="duration_in_months "
              value={formik.values.duration_in_months}
              onChange={(event, value) =>
                formik.setFieldValue("duration_in_months", value)
              }
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Row className="action-buttons">
        <Button type="submit" loading={loading}>
          Calculate
        </Button>
      </Form.Row>
    </StyledForm>
  );
}
const StyledButton = styled(Button)`
  button{
    
  }
`;
const StyledForm = styled(Form)`
  .icon {
    margin-left: 7px;
  }
  .action-buttons{
    button{
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 991px) {
    .col-sm-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .action-buttons {
      margin-top: 24px;
      margin-bottom: 40px;
      button {
        min-width: calc(100% - 30px);
        margin: 0 auto;
        font-size: 1rem;
        font-size: 0.9rem;
      }
    }
  }
`;

LoanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
LoanForm.defaultProps = {
  className: "",
  loading: false,
};
