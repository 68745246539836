import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Heading, Icon } from "components/ui";
import { Chart, Loader } from "components/app";

import Styles from "../styles/StockCardStyle";
import { COLORS } from "utils/constants";
import {
  extractLabelsForGraph,
  extractValuesForGraph,
  formatDisplayNumbers,
} from "utils/misc";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";

export default function StockCard({ data = {}, loading }) {
  const chartOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 8,
            //minRotation: 0,
          },
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              month: "DD MMM YYYY",
            },
            parser: "YYYY-MM-DD",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 9,
            minRotation: 0,
            maxTicksLimit: 3,
          },
        },
      ],
    },
  };

  const chartData = useCallback(
    (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, "rgba(48, 185, 167, .2)");
      gradient.addColorStop(1, "rgba(48, 185, 167, 0)");

      return {
        labels: extractLabelsForGraph(data.prices, "datetime"),
        datasets: [
          {
            data: extractValuesForGraph(data.prices, "datetime", "sellValue"),
            fill: "start",
            backgroundColor: gradient,
            borderWidth: 1,
            borderColor: COLORS.successGreen,
            pointRadius: 0,
            pointHoverRadius: 9,
            pointHoverBorderWidth: 8,
            pointHoverBorderColor: COLORS.white,
            pointBackgroundColor: COLORS.purple,
          },
        ],
      };
    },
    [data]
  );

  return (
    <Styles.StyledCard>
      <Styles.StyledCardHeader>
        <Heading level="6" className="card-title">
          {data.name}
        </Heading>
        {data.changePercentage && (
          <div className="card-info">
            <span>Change</span>
            <span
              className={
                data.changePercentage > 0 ? "text-success" : "text-danger"
              }
            >
              {formatDisplayNumbers(data.changePercentage)}%
            </span>
            <Icon
              src={
                data.changePercentage > 0
                  ? ArrowIncreaseIcon
                  : ArrowDecreaseIcon
              }
            />
          </div>
        )}
      </Styles.StyledCardHeader>
      <Styles.StyledCardContent>
        <Chart
          type="line"
          data={chartData}
          itemData={data}
          chartOptions={chartOptions}
        />
        {loading && <Loader over />}
      </Styles.StyledCardContent>
    </Styles.StyledCard>
  );
}

StockCard.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
