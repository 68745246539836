import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledCard = styled.div`
  background-color: #00284a;
  padding: 1.5rem 2.5rem;
  & .card-header {
    background-color: initial;
    height: 64px;
    border-bottom: 0;
  }
  & .card-body {
    color: ${COLORS.white};
    & .card-title,
    & .card-text {
      padding-bottom: 2rem;
      border-bottom: 1px solid ${COLORS.white}10;
      margin-bottom: 2rem;
    }
  }
`;

export default {
  StyledCard,
};
