import styled from "styled-components";
import { COLORS } from "utils/constants";
import { Table } from "components/app";

const StyledContainer = styled.div``;

const StyledTable = styled(Table)`
  .stock-name-link {
    font-size: 16px;
    font-weight: 700;
  }
  .stock-name {
    font-size: 12px;
  }
  .actions-cell {
    display: flex;
    justify-content: space-between;
    > button {
      height: 35px;
      padding: 0 20px;
      text-transform: capitalize;
      white-space: nowrap;
      &:first-of-type {
        margin-right: 6px;
      }

      @media only screen and (max-width: 1199px) {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export default {
  StyledContainer,
  StyledTable,
};
