import { BoxContainer } from "components/app";
import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledBoxContainer = styled(BoxContainer)`
  &.loan-calculator {
    h6 {
      color: ${COLORS.bluishBlack};
      font-size: 1rem;
    }
    p {
      color: ${COLORS.grey};
      font-size: 0.875rem;
      margin-bottom: 2.5rem;
    }
    .form-label {
      font-size: 0.75rem;
    }
    .MuiInputLabel-root {
      top: -10px;
    }
    .form-control {
      border: none;
      height: 42px;
      margin-top: 2px;
    }
    .col p {
      margin-bottom: 0;
    }
    .action-buttons {
      margin-top: 2.125rem;
    }
  }
  &.prev-loans {
    .bolded {
      color: ${COLORS.bluishBlack};
      font-weight: 700;
    }
  }
`;

export default {
  StyledBoxContainer,
};
