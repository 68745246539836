import React, { useContext, useState, useEffect, useMemo } from "react";
import Moment from "react-moment";
import moment from "moment";
import ReactGA from "react-ga4";

import PeriodContext from "../periodContext";
import { SelectMenu, DatePicker } from "components/ui";

import Style from "../styles/PeriodStyle";

const menuForecastOptions = [
  {
    value: "3",
    label: "Next 3 months",
  },
  {
    value: "6",
    label: "Next 6 months",
  },
  {
    value: "12",
    label: "Next 1 year",
  },
  {
    value: "18",
    label: "Next 18 months",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

const menuExpenseOptions = [
  {
    value: "last-month",
    label: "Last month",
  },
  {
    value: "this-year",
    label: "This year",
  },
  {
    value: "last-year",
    label: "Last year",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

export default function Period() {
  const { period, setPeriod, periodRange, setPeriodRange, isExpense } =
    useContext(PeriodContext);
  const periodObj = useMemo(() => generatePeriod(period), [period]);
  const [values, setValues] = useState({
    from: periodRange?.from || periodObj?.from || null,
    to: periodRange?.to || periodObj?.to || null,
    period,
  });

  useEffect(() => {
    const { from, to } = periodObj;
    if (period !== "custom") {
      setValues((prevValues) => ({
        ...prevValues,
        from,
        to,
      }));
    }
  }, [period, periodObj]);

  const handlePeriodChange = (event) => {
    const { from, to } = periodObj;
    const { value, name } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    setPeriod(value);
    if (value === "custom") {
      setPeriodRange({ from, to });
    }
    // GA-event
    ReactGA.event({
      category: "Forecasts",
      action: isExpense ? "expense_analysis_period" : "forcast_period",
      label: value,
    });
  };

  const handlePickerChange = (value, name) => {
    const formattedValue = moment(value).format("YYYY-MM-DD");
    setValues((prevValues) => ({ ...prevValues, [name]: formattedValue }));
    setPeriodRange({ [name]: formattedValue });
  };

  return (
    <Style.StyledContainer>
      <div className="period">
        Period -
        {period === "custom" ? (
          <div>
            <DatePicker
              id="from"
              name="from"
              value={values.from}
              format="dd.MM.yyyy"
              onChange={(value) => handlePickerChange(value, "from")}
              placeholder="dd.mm.yyyy"
            />
            <DatePicker
              id="to"
              name="to"
              value={values.to}
              format="dd.MM.yyyy"
              onChange={(value) => handlePickerChange(value, "to")}
              placeholder="dd.mm.yyyy"
            />
          </div>
        ) : (
          <div>
            <Moment format="DD.MM.YYYY">{values.from}</Moment> -{" "}
            <Moment format="DD.MM.YYYY">{values.to}</Moment>
          </div>
        )}
      </div>
      <div>
        <SelectMenu
          name="period"
          value={values.period}
          options={!isExpense ? menuForecastOptions : menuExpenseOptions}
          onChange={handlePeriodChange}
        />
      </div>
    </Style.StyledContainer>
  );
}

function generatePeriod(period) {
  const periodTime = {};
  const date = new Date();
  const parsedPeriod = parseInt(period);

  if (!isNaN(parsedPeriod)) {
    periodTime.from = date;
    periodTime.to = new Date(
      date.getFullYear(),
      date.getMonth() + parsedPeriod,
      date.getDate()
    );
  } else {
    switch (period) {
      case "last-month":
        periodTime.from = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          date.getDate()
        );
        periodTime.to = date;
        break;
      case "last-year":
        periodTime.from = new Date(date.getFullYear() - 1, 0, 1);
        periodTime.to = new Date(date.getFullYear() - 1, 11, 31);
        break;
      case "this-month":
        periodTime.from = new Date(date.getFullYear(), date.getMonth(), 1);
        periodTime.to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
      case "this-year":
        periodTime.from = new Date(date.getFullYear(), 0, 1);
        periodTime.to = new Date(date.getFullYear(), 11, 31);
        break;
      default:
        periodTime.from = new Date(date.getFullYear(), date.getMonth(), 1);
        periodTime.to = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate()
        );
    }
  }

  // Format the date
  periodTime.from = moment(periodTime.from).format("YYYY-MM-DD");
  periodTime.to = moment(periodTime.to).format("YYYY-MM-DD");

  return periodTime;
}
