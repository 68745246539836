import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "react-ga4";

import AppRouter from "routing/AppRouter";
import ContextProvider from "components/app/ContextProvider";

// React-Query global configs
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactGA.initialize("G-W08X3SJRS7");

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <AppRouter />
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default App;
