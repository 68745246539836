import React, { isValidElement } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

import { COLORS } from "utils/constants";
import Icon from "./Icon";
import CheckIcon from "assets/icons/check-button.svg";
import CloseIcon from "assets/icons/close-button.svg";
import ChatIcon from "assets/icons/chat_bubble.svg";
import CheckEllipseIcon from "assets/icons/check-ellipse.svg";
import WarningTriangleIcon from "assets/icons/warning-triangle.svg";

export default function MainButton({
  onClick,
  loading,
  disabled,
  variant,
  children,
  infoText,
  startIcon,
  endIcon,
  ...rest
}) {
  const InfoTextIcon = () => {
    switch (variant) {
      case "success":
        return <Icon src={CheckEllipseIcon} />;
      case "danger":
        return <Icon src={WarningTriangleIcon} />;
      default:
        return null;
    }
  };

  const ButtonIcon = (icon) => {
    switch (variant) {
      case "success":
        return showValidElm(icon, <Icon src={CheckIcon} />);
      case "danger":
        return showValidElm(icon, <Icon src={CloseIcon} />);
      case "info":
        return showValidElm(icon, <Icon src={ChatIcon} />);
      default:
        return showValidElm(icon, null);
    }
  };

  return infoText ? (
    <Form.Group>
      <StyledButton
        variant={variant}
        disabled={loading || disabled}
        onClick={!loading ? onClick : null}
        //round={round}
        {...rest}
      >
        {loading ? (
          <CircularProgress size={21} color="inherit" />
        ) : (
          <span className="button-label">
            {startIcon && (
              <span className="button-startIcon">{ButtonIcon(startIcon)}</span>
            )}
            {children}
            {endIcon && (
              <span className="button-endIcon">{ButtonIcon(endIcon)}</span>
            )}
          </span>
        )}
      </StyledButton>
      <StyledFormText className={`text-${variant}`}>
        <InfoTextIcon /> {infoText}
      </StyledFormText>
    </Form.Group>
  ) : (
    <StyledButton
      variant={variant}
      disabled={loading || disabled}
      onClick={!loading ? onClick : null}
      //round={round}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={21} color="inherit" />
      ) : (
        <span className="button-label">
          {startIcon && (
            <span className="button-startIcon">{ButtonIcon(startIcon)}</span>
          )}
          {children}
          {endIcon && (
            <span className="button-endIcon">{ButtonIcon(endIcon)}</span>
          )}
        </span>
      )}
    </StyledButton>
  );
}

function showValidElm(elm, defaultElm) {
  if (elm) {
    const isValidElm = isValidElement(elm);
    if (isValidElm) {
      return elm;
    }
    return defaultElm;
  }
  return null;
}

const StyledButton = styled(({ round, autoWidth, fullWidth, ...rest }) => (
  <Button {...rest} />
))`
  border-radius: 30px;
  min-width: ${(props) =>
    props.autoWidth
      ? "auto"
      : props.round
      ? "40px"
      : props.fullWidth
      ? "100%"
      : "174px"};
  height: ${(props) => (props.round ? 40 : 45)}px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  outline: none;
  &:focus,
  &:hover {
    box-shadow: none;
    text-decoration: none;
  }
  &.profile-button {
    &:active,
    &:visited {
      background-color: ${COLORS.purple} !important;
      border-color: ${COLORS.purple} !important;
    }
  }
  &.profile-button img {
    display: block;
    margin: 0 auto;
  }
  & .button-label {
    justify-content: inherit;
    align-items: inherit;
  }
  & .button-startIcon {
    margin-right: 12px;
    position: relative;
    top: -1px;
  }
  & .button-endIcon {
    margin-left: 20px;
  }
  /* different variants */
  &.btn-primary {
    background-color: ${COLORS.primaryBlue};
    &:hover {
      background-color: ${COLORS.darkBlue};
    }
  }
  &.btn-secondary {
    background-color: ${COLORS.lightBlue};
    color: ${COLORS.primaryBlue};
    border: 1px solid ${COLORS.lightBlue};
    &:hover {
      background-color: ${COLORS.skyBlue} !important;
      border-color: ${COLORS.skyBlue} !important;
      color: ${COLORS.primaryBlue} !important;
    }
    &:active {
      background-color: ${COLORS.skyBlue} !important;
      border-color: ${COLORS.skyBlue} !important;
      color: ${COLORS.primaryBlue} !important;
      box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%) !important;
    }
  }
  &.btn-success {
    background-color: ${COLORS.successGreen};
    border: 1px solid ${COLORS.successGreen};
  }
  &.btn-danger {
    background-color: ${COLORS.dangerRed};
    border: 1px solid ${COLORS.dangerRed};
  }
  &.btn-info {
    background-color: ${COLORS.purple};
    border: 1px solid ${COLORS.purple};
    &:hover {
      background-color: ${COLORS.darkPurple} !important;
      border-color: ${COLORS.darkPurple} !important;
    }
    &:active {
      background-color: ${COLORS.darkPurple} !important;
      border-color: ${COLORS.darkPurple} !important;
      box-shadow: 0 0 0 0.2rem rgb(136 31 211 / 50%) !important;
    }
  }
  &.btn-light {
    background-color: ${COLORS.successGreen};
    border: 1px solid ${COLORS.successGreen};
    color: ${COLORS.white};
  }
  &.btn-outline-light {
    border: 1px solid ${COLORS.lightGrey};
    color: ${COLORS.grey};
  }
`;

const StyledFormText = styled(Form.Text)`
  align-items: center;
  & .icon {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
  }
`;

MainButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  round: PropTypes.bool,
  startIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  endIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};
MainButton.defaultProps = {
  onClick: () => null,
  loading: false,
  disabled: false,
  variant: "primary",
  infoText: null,
  round: false,
  startIcon: null,
  endIcon: null,
};
