import { API_URL } from "utils/constants";
import request from "../request";
import { stringify } from "query-string";

const dataProvider = {
  getList: (resource, params = {}) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;

    const query = {
      // sort: JSON.stringify([field, order]),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // ...(Object.keys(params.filter).length > 0 && {
      //   filter: JSON.stringify(params.filter),
      // }),
      ...(params.query && Object.keys(params.query).length > 0 && params.query),
    };

    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return request(
      url,
      "get",
      {},
      { ...(params.headers || {}) },
      params.responseType
    );
  },

  getOne: (resource, params = {}) => {
    const url = `${API_URL}/${resource}/${params.id}`;

    return request(url, "get", {}, {}, params.responseType);
  },

  getMany: (resource, params = {}) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return request(url, "get", {}, {});
  },

  getManyReference: (resource, params = {}) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    const query = {
      // sort: JSON.stringify([field, order]),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // filter: JSON.stringify({
      //   ...params.filter,
      //   [params.target]: params.id,
      // }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return request(url, "get", {}, {});
  },

  update: (resource, params = {}) => {
    return request(
      `${API_URL}/${resource}/${params.id}`,
      "put",
      JSON.stringify(params.values),
      {}
    );
  },

  updateMany: (resource, params = {}) => {
    const query = {
      //filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return request(url, "put", JSON.stringify(params.values), {}).then(
      ({ data }) => data
    );
  },

  create: (resource, params = {}) => {
    const { formData, values } = params;
    const headers = params.headers || {};
    const body = new FormData();

    if (formData) {
      headers["Content-Type"] = `multipart/form-data`;
      Object.keys(values).forEach((field) => {
        body.append(field, values[field]);
      });
    }

    return request(
      `${API_URL}/${resource}`,
      "post",
      formData ? body : JSON.stringify(values),
      headers
    );
  },

  createExternalResource: (resource, params = {}) => {
    const { formData, values } = params;
    const headers = params.headers || {};
    const body = new FormData();

    if (formData) {
      headers["Content-Type"] = `multipart/form-data`;
      Object.keys(values).forEach((field) => {
        body.append(field, values[field]);
      });
    }

    return request(
        `${API_URL}/${resource}`,
        "post",
        formData ? body : JSON.stringify(values),
        headers
    );
  },

  delete: (resource, params = {}) =>
    request(`${API_URL}/${resource}/${params.id}`, "delete", {}, {}),

  deleteMany: (resource, params = {}) => {
    const query = {
      //filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return request(url, "delete", JSON.stringify(params.values), {});
  },
};

export default dataProvider;
