import React, {useContext, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import Calculations from "services/logic/calculationService";
import { LoanForm, LoanParameters } from "components/app";

import Styles from "../styles/LoanCalculatorStyle";
import AppContext from "../../../services/context";

export default function LoanCalculator() {
  const [loanData, setLoanData] = useState({});
  const { globalData } = useContext(AppContext);

  const handleCreateLoan = async ({ values }) => {
    let data = {};
    let loanCalcUrl = parseLoanCalcUrl(globalData?.loan_calc_cobol_endpoint);

    const { amount, interest_rate, duration_in_months } = values;
    const modifiedData = {

        BRE_I_WS_CALC_WORK_A: amount,
        BRE_I_WS_CALC_WORK_P: interest_rate,
        BRE_I_WS_CALC_WORK_T: duration_in_months,
    };

    const modifiedValsV6 = {
      BRE_INP_INPUT_STRUCTURE: {
        BRE_I_WS_CALC_WORK_A: amount,
        BRE_I_WS_CALC_WORK_P: interest_rate,
        BRE_I_WS_CALC_WORK_T: duration_in_months,
      },
    };
    try {
      let resultFromCall;
      if(loanCalcUrl == null){
        resultFromCall = await dataProvider.create(`loans`, { values: modifiedData });
      } else{
        resultFromCall = await dataProvider.create('external/loanv8',
            { values: {loanCalcRequest: modifiedData, externalLoanUrl: loanCalcUrl} });
        if(resultFromCall.status != StatusCodes.OK)
          resultFromCall = await dataProvider.create('external/loanv6',
              { values: {loanCalcRequest: modifiedValsV6, externalLoanUrl: loanCalcUrl} });
      }
      const res = resultFromCall;
      // const res = await dataProvider.create(`loans`, { values: modifiedData });

      if (res.status === StatusCodes.OK) {
        data = res.data;
      } else {
        data = Calculations.localLoanCalculation(values);
      }
    } catch (e) {
      data = Calculations.localLoanCalculation(values);
    }

    setLoanData({ ...values, ...data });
  };

  function parseLoanCalcUrl(url){
    let loanCalcUrl;
    try{
      loanCalcUrl = new URL(url);
    } catch (e){
      return null;
    }
    return loanCalcUrl;
  }

  const loanCalculate = useMutation(handleCreateLoan);

  const handleCalculate = (values) => {
    loanCalculate.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Loans",
      action: "calculate",
      label: values.type,
    });
  };

  return (
    <Styles.StyledWrapper>
      <Styles.StyledHeading level="4">Loan Calculator</Styles.StyledHeading>
      <Row>
        <Col sm={8}>
          <LoanForm
            onSubmit={handleCalculate}
            loading={loanCalculate.isLoading}
          />
        </Col>
        <Col sm={4}>
          <LoanParameters data={loanData} />
        </Col>
      </Row>
    </Styles.StyledWrapper>
  );
}
