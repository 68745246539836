import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import updateData from "services/api/dataProvider";
import { Button, Link, TextInput, ErrorMessage } from "components/ui";
import { Modal, ConfirmForm } from "components/app";
import UserService from "services/logic/userService";
import { COLORS, VALIDATION_TEXTS } from "utils/constants";

const schema = yup.object().shape({
  firstName: yup.string().required(VALIDATION_TEXTS.required).trim(),
  lastName: yup.string().required(VALIDATION_TEXTS.required).trim(),
  email: yup.string().email().required(VALIDATION_TEXTS.required).trim(),
  password: yup
    .string()
    .required(VALIDATION_TEXTS.required)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      `${VALIDATION_TEXTS.pass_min_length} 8 ${VALIDATION_TEXTS.characters} ${VALIDATION_TEXTS.pass_letter_number}`
    )
    .trim(),
  repeatPass: yup
    .string()
    .test("passwords-match", VALIDATION_TEXTS.pass_not_match, function (value) {
      return this.parent.password === value;
    }),
});

export default function ProfileForm({ onClose }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [error, setError] = useState();

  const { user } = useContext(AppContext);

  const history = useHistory();

  const handleCloseProfile = async ({ username }) => {
    const res = await dataProvider.delete("users", { id: username });
    if (res.status === StatusCodes.OK) {
      UserService.removeUser();
      setIsNotification(true);
    }
  };

  const closeProfile = useMutation(handleCloseProfile);

  const handleNotificationConfirm = () => {
    setIsNotification(false);
    history.push("/");
  };

  const handleUpdateData = async ({ values }) => {
    const res = await updateData.update(`users`, {
      id: values.username,
      values,
    });

    if (res.status === StatusCodes.OK) {
      onClose();
    } else {
      setError(res?.data);
    }
  };

  const updateProfile = useMutation(handleUpdateData);

  const handleSubmit = (values) => {
    updateProfile.mutate({ values });
  };

  const formik = useFormik({
    initialValues: {
      username: user?.username || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      password: "",
      repeatPass: "",
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <StyledForm noValidate onSubmit={formik.handleSubmit}>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-username"
          name="username"
          label="Username"
          value={formik.values.username}
          round
          readOnly
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-email"
          type="email"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          round
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-firstName"
          label="First Name"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.errors.firstName}
          round
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-lastName"
          label="Last Name"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.errors.lastName}
          round
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-password"
          type="password"
          label="Password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          round
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-repeatPass"
          type="password"
          label="Repeat Password"
          name="repeatPass"
          value={formik.values.repeatPass}
          onChange={formik.handleChange}
          error={formik.errors.repeatPass}
          round
        />
      </Form.Row>
      {error?.message && (
        <Form.Group>
          <ErrorMessage error={error.message} />
        </Form.Group>
      )}
      <Form.Group>
        <Link
          type="button"
          onClick={() => setModalOpen(true)}
          className="text-danger"
          style={{ marginBottom: "18px" }}
        >
          Close my profile
        </Link>
      </Form.Group>
      <Form.Group>
        <Form.Row>
          <Button type="submit" loading={updateProfile.isLoading}>
            Save
          </Button>
          <Button type="button" variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Form.Row>
      </Form.Group>
      <Modal
        open={modalOpen}
        onClose={() =>
          isNotification ? handleNotificationConfirm() : setModalOpen(false)
        }
        title="Cancel Account"
        onExited={() => (isNotification ? handleNotificationConfirm() : null)}
      >
        {isNotification ? (
          <>
            <p>Profile was successfully closed.</p>
            <Button type="button" onClick={() => setModalOpen(false)}>
              OK
            </Button>
          </>
        ) : (
          <ConfirmForm
            title="Are you sure you want to close your profile?"
            description={
              <>
                <b>Attention: </b>This action cannot be reverted!
              </>
            }
            onConfirm={() => closeProfile.mutate(user || {})}
            onCancel={() => setModalOpen(false)}
            loading={closeProfile.isLoading}
          />
        )}
      </Modal>
    </StyledForm>
  );
}

ProfileForm.propTypes = {
  className: PropTypes.string,
};

const StyledForm = styled(Form)`
  .form-group .form-row {
    justify-content: space-between;
  }
  .text-danger {
    color: ${COLORS.dangerRed}!important;
  }
`;
