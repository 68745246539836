import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledCard = styled.div`
  border-radius: 10px;
  position: relative;
`;

const StyledCardContent = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
  padding: 1.6rem 2.8rem;
  color: ${COLORS.white};
  text-align: left;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.black};
    opacity: 0.8;
    border-radius: 10px;
  }
  & h6 {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  & p {
    font-size: 0.875rem;
    line-height: 21px;
    margin-bottom: 0;
  }
`;
export default {
  StyledCard,
  StyledCardContent,
};
