import styled from "styled-components";
import { Link } from "@material-ui/core";
import ForgottenPassForm from "../components/ForgottenPassForm";
import NewPassForm from "../components/NewPassForm";
import { Button } from "components/ui";
import { COLORS } from "utils/constants";

const StyledLinkButton = styled(Link)`
  margin-bottom: 1rem !important;
  color: ${COLORS.primaryBlue} !important;
  &:hover {
    color: ${COLORS.grey} !important;
  }
`;

const StyledForgottenPassForm = styled(ForgottenPassForm)`
  margin-top: 1rem;
  
`;

const StyledSubmitButton = styled(Button)`
  margin-top: .875rem;
  text-transform: inherit;
  font-size: 1rem;
`;

const StyledNewPassForm = styled(NewPassForm)``;

export default {
  StyledLinkButton,
  StyledForgottenPassForm,
  StyledSubmitButton,
  StyledNewPassForm,
};
