import React from "react";
import PropTypes from "prop-types";
import { Slider } from "@material-ui/core";
import styled from "styled-components";

import { COLORS } from "utils/constants";
import Tooltip from "./Tooltip";

const ValueLabelComponent = ({ value, ...rest }) => {
  return Tooltip({ value: `${value} MONTHS`, ...rest });
};

export default function SliderComponent({
  id,
  label,
  name,
  onChange,
  min,
  max,
  className,
  ...rest
}) {
  return (
    <StyledContainer>
      <label htmlFor={id}>{label}</label>
      <StyledSlider
        id={id}
        name={name}
        onChange={onChange}
        aria-labelledby="slider"
        ValueLabelComponent={ValueLabelComponent}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        {...rest}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled.div``;

const StyledSlider = styled(Slider)`
  &.MuiSlider-marked {
    margin-bottom: 60px;
  }
  & .MuiSlider-rail {
    height: 11px;
    background-color: #f1f1f1;
  }
  & .MuiSlider-track {
    height: 11px;
    background: linear-gradient(91.31deg, #3e2ab2 1.46%, #c56bd6 100.25%);
  }
  & .MuiSlider-mark {
    display: none;
  }
  & .MuiSlider-thumb {
    width: 27px;
    height: 27px;
    background-color: ${COLORS.white};
    box-shadow: 5px 5px 10px rgba(74, 98, 130, 0.14);
    border-radius: 15px;
    margin-top: -8px;
    margin-left: -14px;
    &:before {
      content: "";
      width: 7px;
      height: 7px;
      background: ${COLORS.purple};
      border-radius: 10px;
    }
    &.Mui-focusVisible,
    &:hover {
      box-shadow: 5px 5px 10px rgba(74, 98, 130, 0.14);
    }
  }
  & .MuiSlider-markLabel {
    top: 60px;
    color: ${COLORS.grey};
    &:before {
      content: "|";
      position: absolute;
      left: 50%;
      transform: translate(-50%, -100%);
      color: ${COLORS.grey};
    }
  }
`;

SliderComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  className: PropTypes.string,
};
SliderComponent.defaultProps = {
  id: null,
  label: null,
  className: "",
};
