import React, { isValidElement } from "react";
import { Link } from "react-router-dom";
import ButtonLink from "@material-ui/core/Link";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLORS } from "utils/constants";
import BlueArrowIcon from "assets/icons/blue_arrow.svg";
import GreyArrowIcon from "assets/icons/grey_arrow.svg";

export default function LinkComponent({
  type,
  onClick,
  to,
  children,
  icon,
  ...rest
}) {
  const linkIcon = icon ? (
    isValidElement(icon) ? (
      icon
    ) : (
      <span className="link-icon"></span>
    )
  ) : null;

  if (type === "button") {
    return (
      <StyledButtonLink component="button" onClick={onClick} {...rest}>
        {children}
        {linkIcon}
      </StyledButtonLink>
    );
  } else {
    return (
      <StyledLink to={to} {...rest}>
        {children}
        {linkIcon}
      </StyledLink>
    );
  }
}

const StyledButtonLink = styled(ButtonLink)`
  color: ${COLORS.primaryBlue} !important; 
  cursor: pointer;
  & .link-icon {
    margin-left: 10px;
    background-image: url(${BlueArrowIcon});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 9px;
    height: 7px;
  }
  &:hover {
    color: ${COLORS.grey} !important;
    text-decoration: none !important;
    .link-icon {
      background-image: url(${GreyArrowIcon});
    }
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${COLORS.primaryBlue};
  &:hover {
    color: ${COLORS.grey};
    text-decoration: none;
    .link-icon {
      background-image: url(${GreyArrowIcon});
    }
  }
  & .link-icon {
    margin-left: 10px;
    background-image: url(${BlueArrowIcon});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 9px;
    height: 7px;
  }
`;

LinkComponent.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};
LinkComponent.defaultProps = {
  type: "",
  onClick: () => null,
  to: "",
  icon: null,
};
