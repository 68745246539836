import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button, Icon } from "components/ui";
import PaperIcon from "assets/icons/paper.svg";

export default function ExportGroup({ onExport, loading, className }) {
  const [clickedBtn, setClickedBtn] = useState(null);

  const handleExport = (type) => {
    setClickedBtn(type);
    onExport(type);
  };

  return (
    <StyledGroup className={className}>
      <Button
        variant="link"
        onClick={() => handleExport("csv")}
        round
        disabled={loading && clickedBtn === "csv"}
      >
        <Icon src={PaperIcon} /> XLS
      </Button>
      <Button
        variant="link"
        onClick={() => handleExport("pdf")}
        round
        disabled={loading && clickedBtn === "pdf"}
      >
        <Icon src={PaperIcon} /> PDF
      </Button>
    </StyledGroup>
  );
}

const StyledGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -1.5rem;
  margin-bottom: 1.2rem;
`;

ExportGroup.propTypes = {
  onExport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
ExportGroup.defaultProps = {
  className: "",
  loading: false,
};
