import styled from "styled-components";
import { Form } from "react-bootstrap";

import { COLORS } from "utils/constants";

const StyledForm = styled(Form)`
  width: 62%;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
  .form-group {
    margin-bottom: 1.25rem;
  }
  > label {
    margin: 1.5rem 0 2rem;
    font-weight: 700;
    font-size: 1.2rem;
  }
  .form-label {
    font-size: 0.75rem;
    margin-bottom: 10px;
    &.big-label {
      color: ${COLORS.black} !important;
      font-size: 1rem;
      margin-bottom: 40px;
    }
  }
  .MuiInputLabel-formControl {
    top: -10px;
  }
  .radio-buttons-group {
    margin-bottom: 40px;
  }
  .form-check-inline {
    margin-right: 20px;
    .form-check-input {
      margin-right: 10px;
    }
  }
  .submit-btn {
    margin-top: 20px;
  }
`;

const StyledDetails = styled.div`
  margin: 1rem 0 2rem;
  span {
    color: ${COLORS.bluishBlack};
    display: inline-block;
    font-weight: 700;
    margin-left: 10px;
  }
  p {
    color: ${COLORS.grey};
    font-size: 0.75rem;
  }
`;

export default { StyledForm, StyledDetails };
