import React from "react";
import { useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";

import Style from "./LandingPageStyle";
import LandingCard from "./components/LandingCard";

import advantageShoppingLogo from "assets/images/advantageShopping-logo.svg";
import advantageBankLogoWhite from "assets/images/new-advantageBank-logo-white.svg";
import ReactGA from "react-ga4";

export default function LandingPage() {
  const history = useHistory();
  ReactGA.event({
    category: "LandingPage",
    action: "landing_page_hit",
  });

  const openAdvShopping = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "LandingPage",
      action: "landing_navigate_to_AOS",
    });
    window.open(
        "https://advantageonlineshopping.com/#/",
        "_blank"
    )
  };

  const openAdvBanking = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "LandingPage",
      action: "landing_navigate_to_AOB",
    });
    history.push("/");
  };

  return (
    <Style.StyledWrapper>
      <Container>
        <Style.StyledRow>
          <Col>
            <Style.StyledHeading level="2">
              Showcasing Micro Focus solutions
            </Style.StyledHeading>
          </Col>
          <Col>
            <Style.StyledHeading level="bodi1" component="p">
              Welcome to the Advantage Online demo site. Click the links below to see how various Micro Focus products can work with simulated customer applications, micro-services, and APIs.
            </Style.StyledHeading>
          </Col>
        </Style.StyledRow>
        <Row>
          <Style.StyledCol>
            <LandingCard
              header={
                <img src={advantageShoppingLogo} alt="Advantage Shoping" />
              }
              headingLevel="3"
              title="Advantage Online Shopping"
              text="Try out the Micro Focus “Advantage Online Shopping” demo application"
              action={
                <Style.StyledButton
                  onClick={openAdvShopping}
                >
                  Get started
                </Style.StyledButton>
              }
            />
          </Style.StyledCol>
          <Style.StyledCol>
            <LandingCard
              header={<img src={advantageBankLogoWhite} alt="Advantage Bank" />}
              headingLevel="3"
              title="Advantage Online Banking"
              text="Try out the Micro Focus “Advantage Online Banking” demo application"
              action={
                <Style.StyledButton onClick={openAdvBanking}>
                  Get started
                </Style.StyledButton>
              }
            />
          </Style.StyledCol>
        </Row>
      </Container>
    </Style.StyledWrapper>
  );
}
