import React, { useMemo, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import AppContext from "services/context";
import useWindowSize from "hooks/useWindowSize";
import NewsCard from "./NewsCard";

import Styles from "../styles/LatestNewsStyle";
import { RESPONSIVE_SIZES } from "utils/constants";

export default function LatestNews() {
  const { globalData = {} } = useContext(AppContext);

  const size = useWindowSize();

  const newsData = useMemo(
    () =>
      globalData.home_page_news && typeof globalData.home_page_news === "string"
        ? JSON.parse(globalData?.home_page_news)
        : globalData.home_page_news || [],
    [globalData]
  );
  const columnViewData = useMemo(
    () => newsData.filter((news, index) => index % 3 === 0),
    [newsData]
  );
  const listViewData = useMemo(
    () => newsData.filter((news, index) => index % 3 !== 0),
    [newsData]
  );

  return (
    <Styles.StyledWrapper>
      <Styles.StyledHeading level="4">Latest News</Styles.StyledHeading>
      <Row>
        <Col sm={12} lg={6}>
          {columnViewData.map((news, index) => (
            <NewsCard
              key={index}
              isListView={false}
              img={news.picture}
              title={news.heading}
              postDate={moment().subtract(index, "d").format() /*news.date*/}
              text={news.text}
              url={news.url || "#"}
              className="big-news-container"
            />
          ))}
        </Col>
        <Col sm={12} lg={6}>
          {listViewData.map((news, index) => (
            <NewsCard
              key={index}
              isListView={size.width >= RESPONSIVE_SIZES.sm}
              img={news.picture}
              title={news.heading}
              postDate={moment().subtract(index, "d").format() /*news.date*/}
              text={news.text}
              url={news.url || "#"}
            />
          ))}
        </Col>
      </Row>
    </Styles.StyledWrapper>
  );
}
