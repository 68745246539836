import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Button, Heading } from "components/ui";

import Styles from "./UnderConstructionPageStyle";
import logoWhite from "assets/images/advantageBank-logo-white.svg";

export default function LandingPage() {
  const history = useHistory();

  return (
    <Styles.StyledContainer fluid>
      <header>
        <Link to="/">
          <img src={logoWhite} alt="Advantage Bank" />
        </Link>
      </header>
      <Styles.StyledContent>
        <Container>
          <Heading level="1" style={{ fontSize: 72, fontWeight: "bold" }}>
            Under Construction
          </Heading>
          <p>
            This page is still under development!
            <br />
            Coming soon
          </p>
          <Styles.StyledActionsContainer>
            <Button onClick={() => history.push("/")}>Back to AOB</Button>
            <Button
              onClick={() => {
                window.open(
                  "https://management-ci.advantageonlinebanking.com/",
                  "_blank"
                );
              }}
            >
              Management Console
            </Button>
          </Styles.StyledActionsContainer>
        </Container>
      </Styles.StyledContent>
    </Styles.StyledContainer>
  );
}
