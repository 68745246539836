import React from "react";
import PropTypes from "prop-types";

import { Heading } from "components/ui";

import Styles from "../styles/ServiceCardStyle";

export default function ServiceCard({ img, title, subtitle, className }) {
  return (
    <Styles.StyledCard className={className}>
      <img src={img} />
      <Styles.StyledCardContent>
        <Heading level="6">{title}</Heading>
        <p>{subtitle}</p>
      </Styles.StyledCardContent>
    </Styles.StyledCard>
  );
}

ServiceCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};
ServiceCard.defaultProps = {
  title: "",
  subtitle: "",
  className: "",
};
