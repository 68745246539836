import styled from "styled-components";
import { Container } from "react-bootstrap";

import bgLogo from "assets/images/under-construction-bg_logo.png";
import dashesLeft from "assets/images/uc-actions_dashes.png";
import dashesRight from "assets/images/uc-actions_dashes2.png";
import { COLORS } from "utils/constants";

const StyledContainer = styled(Container)`
  background: radial-gradient(
    circle,
    rgba(0, 62, 113, 0.85) 0%,
    rgba(0, 62, 113, 1) 60%
  );
  padding-top: 3rem;
  
  @media only screen and (max-width: 767px) {
    padding-top: 1.5rem;
  }
  
  header {
    margin-left: 5%;
  }
  height: 100vh;
  overflow: hidden;
`;

const StyledContent = styled.main`
  background: url(${bgLogo}) center 5rem no-repeat;
  padding: 25rem 6rem 0;
  text-align: center;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    background-size: 40%;
    background-position: top;
    padding: 10rem 0 0;

    h1 {
      font-size: 2.5rem !important;
    }
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    background-size: 20%;
    padding-top: 3rem;
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    padding-top: 0;
  }

  h1 {
    color: ${COLORS.turquoise};
    font-size: 72px;
  }
  p {
    color: ${COLORS.white};
    margin-bottom: 3rem;

    @media only screen and (max-width: 360px) {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) and (orientation: landscape) {
      margin-bottom: 0;
    }
  }
`;

const StyledActionsContainer = styled.div`
  display: inline-block;
  position: relative;
  padding: 0 17px;

  @media only screen and (max-width: 360px) {
    padding: 0;
  }

  button {
    width: 275px;
    margin: 0 40px;

    @media only screen and (max-width: 991px) {
      display: block;
      margin: 20px auto 0;
    }
    @media only screen and (max-width: 360px) {
      width: 260px;
    }

    &:last-of-type {
      background-color: #e5f1fd;
      color: ${COLORS.primaryBlue};
      &:hover {
        background-color: #b8c2cc;
      }
    }
  }
  &:before {
    background: url(${dashesLeft});
    content: "";
    position: absolute;
    left: 0;
    top: -50%;
    width: 57px;
    height: 93px;
  }
  &:after {
    background: url(${dashesRight});
    content: "";
    position: absolute;
    right: 0;
    top: -50%;
    width: 57px;
    height: 93px;
  }
`;

export default {
  StyledContainer,
  StyledContent,
  StyledActionsContainer,
};
