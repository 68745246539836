import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import {
  Button,
  ErrorMessage,
  TextInput,
  SelectInput,
  CheckInput,
} from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";
import { formatDisplayNumbers, optionsFromData } from "utils/misc";

import Styles from "../styles/ExternalRecipientFormStyle";

const schema = yup.object().shape({
  iban: yup.string().required(VALIDATION_TEXTS.required),
  fillType: yup.string().required(VALIDATION_TEXTS.required),
  recipient: yup.object().shape({
    name: yup.string().required(VALIDATION_TEXTS.required).trim(),
    iban: yup.string().required(VALIDATION_TEXTS.required).trim(),
    bic: yup.string().required(VALIDATION_TEXTS.required).trim(),
    bankName: yup.string().required(VALIDATION_TEXTS.required).trim(),
  }),
  amount: yup
    .number()
    .positive()
    .required(VALIDATION_TEXTS.required)
    .max(yup.ref("balance"), `${VALIDATION_TEXTS.less_ammount} Balance`),
});

export default function ExternalRecipientForm({
  className,
  accounts,
  merchants,
}) {
  const [account, setAccount] = useState({});
  const [initialTransaction, setInitialTransaction] = useState({});
  const [error, setError] = useState();

  const { state } = useLocation();
  const history = useHistory();

  // Get previous transactions data for dropdown
  const { data: transactionsData = [] } = useQuery(
    ["transactions", account],
    () =>
      account.iban &&
      dataProvider
        .getList(`accounts/${account.iban}/previous-recipients`)
        .then((res) => res?.data || [])
        .catch((err) => err)
  );

  const initialAccount = useMemo(
    () => state || accounts[0] || {},
    [state, accounts]
  );

  useEffect(() => {
    setAccount(initialAccount);
  }, [initialAccount]);

  useEffect(() => {
    if (!initialTransaction.iban && transactionsData.length > 0) {
      setInitialTransaction(transactionsData[0]);
    }
  }, [transactionsData]);

  const handleSendTransfer = async (values) => {
    const res = await dataProvider.create(
      `accounts/${values.values.iban}/transactions`,
      values
    );
    if (res.status === StatusCodes.OK) {
      history.push("/accounts");
    } else {
      typeof res === "string" ? setError(res) : setError(res?.data);
    }
  };
  const newTransfer = useMutation(handleSendTransfer);

  const handleSubmit = (values) => {
    newTransfer.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Accounts",
      action: "transfer_to_external",
      label: values.iban,
    });
  };

  const formik = useFormik({
    initialValues: {
      iban: initialAccount.iban || "",
      balance: initialAccount.balance || "",
      fillType: "1",
      previousTransactions: "",
      previousMerchants: "",
      recipient: {
        name: "",
        iban: "",
        bic: initialAccount.bankBic || "",
        bankName: initialAccount.bankName || "",
        company_id: null,
      },
      amount: "",
      currencyCode: initialAccount.currency_code || "",
      description: "",
      transaction_type: "other",
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleAccountChange = (event) => {
    const newAcc =
      accounts.find((item) => item.iban === event.target.value) || {};
    formik.handleChange(event);
    formik.setFieldValue("balance", newAcc.balance || "");
    formik.setFieldValue("currencyCode", newAcc.currency_code || "");
    setAccount(newAcc);
  };

  const handleTransactionChange = (event) => {
    const transaction =
      transactionsData.find((item) => item.iban === event.target.value) || {};

    formik.setValues({
      ...formik.values,
      [event.target.name]: event.target.value,
      previousMerchants: "",
      recipient: {
        name: transaction.name || "",
        iban: transaction.iban || "",
        bic: transaction.bankBic || transaction.bic || "",
        bankName: transaction.bankName || "",
        company_id: transaction.id || null,
      },
      transaction_type: transaction.type || "",
    });
  };

  const handleMerchantChange = (event) => {
    const merchant =
      merchants.find((item) => item.id === event.target.value) || {};

    formik.setValues({
      ...formik.values,
      [event.target.name]: event.target.value,
      previousTransactions: "",
      recipient: {
        name: merchant.name || "",
        iban: merchant.iban || "",
        bic: merchant.bankBic || "",
        bankName: merchant.bankName || "",
        company_id: merchant.id || null,
      },
      transaction_type: merchant.type || "",
    });
  };

  const accountFromIban = (iban) => {
    const acc = accounts.find((item) => item.iban === iban) || {};
    return acc;
  };

  const filteredTransactions = useMemo(
    () =>
      transactionsData.length &&
      transactionsData.filter(
        (t) => t.iban !== formik.values.previousTransactions
      ),
    [transactionsData, formik.values.previousTransactions]
  );

  const filteredMerchants = useMemo(
    () => merchants.filter((t) => t.id !== formik.values.previousMerchants),
    [merchants, formik.values.previousMerchants]
  );

  return (
    <Styles.StyledForm
      noValidate
      onSubmit={formik.handleSubmit}
      className={className}
    >
      <Form.Label className="big-label">Sender Account</Form.Label>
      <Form.Row>
        <Col lg={9}>
          <SelectInput
            id="form-iban"
            name="iban"
            label="Account"
            value={formik.values.iban}
            options={optionsFromData(accounts, "iban", [
              "name",
              "currency_code",
              "iban",
            ])}
            onChange={handleAccountChange}
            onBlur={formik.handleBlur}
            required
          />
          <ErrorMessage error={formik.errors.iban} />
        </Col>
        <Col lg={3}>
          <TextInput
            id="form-balance"
            name="balance"
            label="Balance"
            value={
              formatDisplayNumbers(formik.values.balance, 2) +
              " " +
              accountFromIban(formik.values.iban).currency_code
            }
            readOnly
          />
        </Col>
      </Form.Row>
      <Form.Label className="big-label">Recipient Details</Form.Label>
      <Form.Group className="radio-buttons-group">
        <CheckInput
          id="form-fillType-manual"
          type="radio"
          label="Manual"
          name="fillType"
          value="1"
          checked={formik.values.fillType === "1"}
          onChange={formik.handleChange}
          inline
        />
        <CheckInput
          id="form-fillType-previous"
          type="radio"
          label="Fill from previous transactions"
          name="fillType"
          value="2"
          checked={formik.values.fillType === "2"}
          onChange={formik.handleChange}
          inline
        />
        <CheckInput
          id="form-fillType-existing"
          type="radio"
          label="Fill from existing merchants"
          name="fillType"
          value="3"
          checked={formik.values.fillType === "3"}
          onChange={formik.handleChange}
          inline
        />
        <ErrorMessage error={formik.errors.fillType} />
      </Form.Group>
      {formik.values.fillType === "2" && (
        <Form.Row>
          <Col lg={9}>
            <SelectInput
              id="form-previousTransactions"
              name="previousTransactions"
              label="Previous Transactions"
              value={formik.values.previousTransactions}
              options={optionsFromData(filteredTransactions, "iban", "name")}
              renderValue={(selected) => {
                return "From previous transactions";
              }}
              displayEmpty
              onChange={handleTransactionChange}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Form.Row>
      )}
      {formik.values.fillType === "3" && (
        <Form.Row>
          <Col lg={9}>
            <SelectInput
              id="form-previousMerchants"
              name="previousMerchants"
              label="Previous Merchants"
              value={formik.values.previousMerchants}
              options={optionsFromData(filteredMerchants, "id", "name")}
              renderValue={(selected) => {
                return "From previous merchants";
              }}
              displayEmpty
              onChange={handleMerchantChange}
              onBlur={formik.handleBlur}
            />
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "9" }}
          id="form-name"
          name="recipient.name"
          label="Recipient Name"
          value={formik.values.recipient.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.recipient?.name}
        />
        <TextInput
          groupProps={{ as: Col, md: "9" }}
          id="form-iban"
          name="recipient.iban"
          label="Recipient IBAN"
          value={formik.values.recipient.iban}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.recipient?.iban}
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "3" }}
          id="form-bic"
          name="recipient.bic"
          label="Bic"
          value={formik.values.recipient.bic}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.recipient?.bic}
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-bankName"
          name="recipient.bankName"
          label="Bank"
          value={formik.values.recipient.bankName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.recipient?.bankName}
        />
      </Form.Row>
      <Form.Label className="big-label">Transaction Details</Form.Label>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "7" }}
          type="number"
          id="form-amount"
          name="amount"
          label="Amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.amount}
        />
        <TextInput
          groupProps={{ as: Col, md: "2" }}
          id="form-currencyCode"
          name="currencyCode"
          label="Currency"
          value={formik.values.currencyCode}
          //onChange={formik.handleChange}
          readOnly
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "9" }}
          id="form-description"
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Row>
      {error && <ErrorMessage error={error?.message ?? error} />}
      <div>
        <Button
          type="submit"
          className="submit-btn"
          loading={newTransfer.isLoading}
        >
          Send
        </Button>
      </div>
    </Styles.StyledForm>
  );
}

ExternalRecipientForm.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  merchants: PropTypes.array.isRequired,
};
ExternalRecipientForm.defaultProps = {
  className: "",
};
