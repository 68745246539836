import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledHeader = styled.p`
  span {
    color: ${COLORS.grey};
    margin-left: 1rem;
  }
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  > .actions {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    .action-group {
      margin-right: 3rem;
      @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      > button {
        padding: 0 20px;
      }

      > span {
        color: ${COLORS.grey};
        margin-left: 15px;
      }
    }
  }
`;

export default {
  StyledHeader,
  StyledActions,
};
