import styled from "styled-components";
import { Col } from "react-bootstrap";
import { Container } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";

import { COLORS } from "utils/constants";

import LoginForm from "../components/LoginForm";
import RegistrationForm from "../components/RegistrationForm";

const StyledWrapper = styled.div`
  position: relative;
  @media only screen and (max-width: 768px) {
    .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
  & .MuiContainer-fixed {
    max-width: 1140px;
    height: 100%;
  }
`;

const StyledCarousel = styled(Carousel)`
  position: relative;
  height: 603px;
  @media only screen and (max-width: 767px) {
    height: 100%;
  }

  .carousel-slider,
  .slider-wrapper,
  .slider {
    height: 100%;
  }

  & img {
    object-fit: cover;
    height: 100%;
    } & .control-arrow.control-prev.control-disabled{
    display: none!important;
  }
  & .control-dots {
    display: none!important;
    width: 20px;
    height: 100%;
    left: 0;
    right: 0;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .dot {
      position: relative;
      margin: 12px 0;
      width: 6px;
      height: 6px;
      &.selected {
        &:before {
          content: "";
          border: 1px white solid;
          border-radius: 50%;
          display: block;
          width: 26px;
          height: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      margin-left: 50px;
    }
    @media (min-width: 600px) {
      //max-width: 600px;
    }
    @media (min-width: 960px) {
      //max-width: 960px;
    }
    @media (min-width: 1280px) {
      //max-width: 1280px;
    }
  }
`;

const StyledFixedContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 5.5rem 0;

  @media only screen and (max-width: 767px) {
    top: 180px;
  }

  & .row {
    align-items: center;
  }
`;

const StyledFormContainer = styled.div`
  background-color: ${COLORS.white};
  padding: 2rem 2.5rem;
  border-radius: 20px;
  min-width: 330px;
  justify-content: flex-end;

  @media only screen and (max-width: 991px) {
    padding: 1.875rem;
    max-height: 421px;
    overflow-y: auto;

    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 767px) {
    min-width: 100%;
    .form-check-input {
      position: absolute !important;
      margin-right: 0;
    }
    .form-check-label {
      margin-top: -5px;
      margin-left: 10px;
    }
  }
`;

const StyledSlide = styled.div`
  position: relative;
  height: 100%;
  @media only screen and (max-width: 767px) {
    height: 110vh;
  }
  @media only screen and (max-width: 370px) {
    height: 130vh;
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    height: 250vh;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.black}50;
  }
`;

const StyledInfo = styled(Col)`
  color: ${COLORS.white};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20%;
  max-width: 50%;

  h1,
  h6 {
    text-align: left;
    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-top: 5px;
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1024px) {
    left: 80px;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    top: 100px;
    left: 0;
    transform: none;

    h1 {
      font-size: 2.5rem !important;
      text-align: left;
    }
  }
`;

const StyledForms = styled(Col)`
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  @media only screen and (max-width: 767px) {
    position: relative;
    top: auto;
    transform: none;
    right: auto;
  }
`;

const StyledInfoBox = styled.div`
  > h5 {
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid ${COLORS.lightGrey3};
  }
`;

const StyledLoginForm = styled(LoginForm)`
  margin-top: 2rem;
`;

const StyledRegistrationForm = styled(RegistrationForm)`
  margin-top: 2rem;
`;

export default {
  StyledWrapper,
  StyledCarousel,
  StyledFixedContainer,
  StyledFormContainer,
  StyledSlide,
  StyledInfo,
  StyledForms,
  StyledInfoBox,
  StyledLoginForm,
  StyledRegistrationForm,
};
