import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import { Heading } from "components/ui";

import Styles from "../styles/LandingCardStyle";

export default function LandingCard({
  className,
  header,
  headingLevel,
  title,
  text,
  action,
}) {
  return (
    <Styles.StyledCard className={className}>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Heading className="card-title" level={headingLevel}>
          {title}
        </Heading>
        <Card.Text>{text}</Card.Text>
        {action}
      </Card.Body>
    </Styles.StyledCard>
  );
}

LandingCard.propTypes = {
  className: PropTypes.string,
  header: PropTypes.element.isRequired,
  headingLevel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.element.isRequired,
};
