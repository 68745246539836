import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText } from "@material-ui/core";
import styled from "styled-components";

import { COLORS } from "utils/constants";
import { formatDisplayNumbers } from "utils/misc";
import Card from "../Card";

export default function LoadParameters({ data }) {
  const sumOfMonthly =
    data.BRE_OUT_OUTPUT_STRUCTURE?.BRE_O_WS_LOAN_MONTHL *
      data.duration_in_months || null;

  return (
    <Card title="Loan Parameters">
      <StyledList>
        <ListItem>
          <ListItemText>Currency</ListItemText>
          <ListItemText>{data.currency}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Monthly payment</ListItemText>
          <ListItemText className="accent">
            {formatDisplayNumbers(
              data.BRE_OUT_OUTPUT_STRUCTURE?.BRE_O_WS_LOAN_MONTHL,
              2
            )}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Interest rate</ListItemText>
          <ListItemText>
            {data.interest_rate}
            {data.interest_rate && "%"}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Sum of monthly payments</ListItemText>
          <ListItemText>{formatDisplayNumbers(sumOfMonthly, 2)}</ListItemText>
        </ListItem>
      </StyledList>
    </Card>
  );
}

const StyledList = styled(List)`
  &.MuiList-root {
    padding: 1.5rem 0 0 0;
    .MuiListItem-root {
      padding: 0.75rem 1.2rem;
      .MuiListItemText-root:first-of-type span {
        color: ${COLORS.grey};
        font-size: 0.875rem;
        font-weight: 300;
      }
      .MuiListItemText-root:last-of-type span {
        text-align: right;
        color: ${COLORS.grey4};
        font-weight: 700;
      }
      .accent span {
        color: ${COLORS.black} !important;
      }
    }
  }
`;

LoadParameters.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};
LoadParameters.defaultProps = {
  className: "",
};
