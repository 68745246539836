import { convertCurrencyRates, roundNumber } from "utils/misc";

export default class Calculations {
  static sumDataValues(data, field) {
    if (Array.isArray(data)) {
      let value = null;
      data.forEach((item) => {
        const number = item[field];
        if (number && !isNaN(number)) {
          value += number;
        }
      });
      return value;
    }
    return false;
  }

  static sumCurrencyValues(
    data,
    field,
    defaultCurrency = "EUR",
    currencyField,
    currencyRates
  ) {
    if (Array.isArray(data)) {
      let value = null;
      data.forEach((item) => {
        const number = item[field];
        if (number && !isNaN(number)) {
          if (currencyField && currencyRates) {
            const rate = convertCurrencyRates(
              defaultCurrency,
              item[currencyField],
              currencyRates
            );
            value += number * rate;
          } else {
            value += number;
          }
        }
      });
      return value;
    }
    return false;
  }

  static localLoanCalculation(values = {}) {
    const data = {
      BRE_OUT_OUTPUT_STRUCTURE: {
        BRE_O_WS_LOAN_MONTHL: null,
      },
    };
    const { amount, interest_rate, duration_in_months } = values;

    if (isNaN(amount) || isNaN(interest_rate) || isNaN(duration_in_months)) {
      return data;
    }

    let loanMonthlyPayment = 0;
    const loanInterest = interest_rate / 100 / 12;
    const loanPrincipal = amount;
    const loanTerm = duration_in_months;

    loanMonthlyPayment =
      ((loanInterest * Math.pow(1 + loanInterest, loanTerm)) /
        (Math.pow(1 + loanInterest, loanTerm) - 1)) *
      loanPrincipal;

    if (isNaN(loanMonthlyPayment)) {
      return data;
    }

    data.BRE_OUT_OUTPUT_STRUCTURE.BRE_O_WS_LOAN_MONTHL = roundNumber(
      loanMonthlyPayment,
      2
    );

    return data;
  }
}
