import React, { useState, useMemo, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-query";
import { Link } from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import ReactGA from "react-ga4";

import AppContext from "services/context";
import dataProvider from "services/api/dataProvider";
import { Button } from "components/ui";
import { Table, TableColumn, Modal, NumberCell } from "components/app";
import { COLORS } from "utils/constants";

import Icon from "components/ui/Icon";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import AdvantageBankLogo from "assets/images/advantageBank-logo.svg";
import { formatDisplayNumbers, limitArrayOfData } from "utils/misc";

const toDate = moment().subtract(1, "days").format("YYYY-MM-DD");
const fromDate = moment().subtract(2, "days").format("YYYY-MM-DD");

export default function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  const { globalData, setCurrencyRates } = useContext(AppContext);

  const iticks = window?.iticks;

  const {
    data: ratesData = [],
    isLoading,
    isFetching,
  } = useQuery("currencyRates", () =>
    dataProvider
      .getList(`currency`, {
        query: { from: fromDate, to: toDate },
      })
      .then((res) => {
        const resData = res?.data || [];

        if (!Array.isArray(resData)) {
          return [];
        }
        // Compare currency rates from yesterday and today
        // Based on that include additional tag "positive"
        const currencyRates = resData.reduce((currencies, currencyFromDate) => {
          const comparedCurrencies = [];
          currencyFromDate.currency.forEach((curr) => {
            const existCurr = currencies.find(
              (currency) => currency.name === curr.name
            );
            if (!existCurr) {
              comparedCurrencies.push(curr);
            } else {
              const isPositive =
                curr.rate === existCurr.rate
                  ? null
                  : curr.rate > existCurr.rate;
              comparedCurrencies.push({ ...curr, positive: isPositive });
            }
          });

          return comparedCurrencies;
        }, []);

        setCurrencyRates(currencyRates);

        return currencyRates;
      })
      .catch((err) => err)
  );

  const limitedCurrencyRates = useMemo(
    () => limitArrayOfData(ratesData, 4),
    [ratesData]
  );

  const handleOpenChat = () => {
    if (iticks && typeof iticks?.call === "function") {
      iticks.call("show", "maximize");
    }
    // GA-event
    ReactGA.event({
      category: "General",
      action: "live_chat_start",
    });
  };

  const handleViewMore = () => {
    setModalOpen(true);
    // GA-event
    ReactGA.event({
      category: "Currencies",
      action: "view_more_rates",
    });
  };

  return (
    <footer>
      <StyledTopBar>
        <Container>
          <StyledGroupContainer>
            <StyledCurrencyContainer>
              {limitedCurrencyRates.map((currency, index) => (
                <StyledGroup key={index}>
                  <span>{currency.name}</span>
                  <span
                    className={
                      currency.positive === null
                        ? ""
                        : currency.positive
                        ? "greenText"
                        : "redText"
                    }
                  >
                    {formatDisplayNumbers(currency.rate, 3)}
                  </span>
                  {currency.positive !== null && (
                    <Icon
                      src={
                        currency.positive
                          ? ArrowIncreaseIcon
                          : ArrowDecreaseIcon
                      }
                    />
                  )}
                </StyledGroup>
              ))}
              <Link
                type="button"
                component="button"
                variant="body2"
                onClick={handleViewMore}
                underline="none"
              >
                View more
              </Link>
            </StyledCurrencyContainer>
          </StyledGroupContainer>
        </Container>
      </StyledTopBar>

      <Container>
        <StyledInfoContent>
          <RouterLink to="/" className="footer-logo">
            <Icon src={AdvantageBankLogo} />
          </RouterLink>
          <StyledRightSide>
            <RouterLink to="/under-construction">Contacts</RouterLink>
            <RouterLink to="/under-construction">
              Frequently Asked Questions
            </RouterLink>
            <Button onClick={handleOpenChat} variant="info" endIcon>
              Live Support
            </Button>
          </StyledRightSide>
        </StyledInfoContent>

        <StyledBottomBar>
          <Col>
            <span>
              {globalData?.bank_name} {new Date().getFullYear()} v1.2.0
            </span>
          </Col>
          <Col className="text-center">
            <RouterLink to="/under-construction" className="footer-logo">
              Terms and conditions
            </RouterLink>
          </Col>
          <Col className="text-right">
            <RouterLink to="/under-construction" className="footer-logo">
              Privacy Policy
            </RouterLink>
          </Col>
        </StyledBottomBar>
      </Container>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Currency Rates"
      >
        <Table data={ratesData} loading={isLoading || isFetching} noGutters>
          <TableColumn
            title="Currency"
            field="name"
            style={{ color: COLORS.bluishBlack, fontWeight: "bold" }}
          />
          <TableColumn
            title="BUY"
            field="buy"
            className="text-success"
            cell={({ item, field }) => NumberCell({ item, field }, 3)}
            align="right"
          />
          <TableColumn
            title="FIX"
            field="rate"
            cell={({ item, field }) => NumberCell({ item, field }, 3)}
            align="right"
          />
          <TableColumn
            title="SELL"
            field="sell"
            className="text-danger"
            cell={({ item, field }) => NumberCell({ item, field }, 3)}
            align="right"
          />
        </Table>
      </Modal>
    </footer>
  );
}

const StyledTopBar = styled.div`
  background-color: ${COLORS.bluishBlack};
  padding: 1rem 0;
`;

const StyledGroupContainer = styled.div`
  text-align: center;
`;

const StyledCurrencyContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  margin-right: 40px;

  > button {
    color: ${COLORS.white};
    text-transform: uppercase;
    opacity: 0.5;
    white-space: nowrap;
    font-size: 12px;
  }

  @media only screen and (min-width: 992px) {
    margin-right: 0;
    white-space: normal;
  }

  div {
    display: inline-block;
  }
`;

const StyledGroup = styled.div`
  margin-right: 2rem;
  @media only screen and (max-width: 991px) {
    margin-right: 10px;
  }

  & > span {
    margin: 0 6px;
    color: ${COLORS.white};
    font-size: 12px;
    font-weight: bold;
    &.redText {
      color: ${COLORS.dangerRed};
    }
    &.greenText {
      color: ${COLORS.successGreen};
    }
  }
`;

const StyledInfoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
  border-bottom: 1px solid ${COLORS.lightGrey};
  font-size: 14px;

  @media only screen and (max-width: 991px) {
    display: block;
    .footer-logo {
      display: block;
      margin: 0 auto 40px;
      text-align: center;
    }
  }
`;

const StyledRightSide = styled.div`
  display: flex;
  align-items: center;
  > .form-group {
    margin: 0;
  }
  > a {
    margin-right: 50px;
    color: ${COLORS.grey};
    &:hover {
      text-decoration: none;
    }
  }

  button {
    text-transform: initial;

    .button-endIcon {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      display: block;
    }
  }

  @media only screen and (max-width: 991px) {
    display: block;
    padding-top: 40px;
    border-top: 1px solid ${COLORS.lightGrey};

    a {
      font-size: 14px;
      display: block;
      margin: 0 auto 40px;
      text-align: center;
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }
`;

const StyledBottomBar = styled(Row)`
  padding: 1.6rem 0;
  & a,
  & span {
    font-size: 12px;
    color: ${COLORS.bluishBlack}50;
  }
  & a:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
    .col,
    a {
      display: block;
      flex-basis: auto;
      text-align: center !important;
    }
    .col {
      margin: 0 auto 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
