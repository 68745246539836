import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  .period {
    display: flex;
    align-items: center;
    color: ${COLORS.grey};
    font-size: 12px;
    font-weight: 500;
    > span {
      color: ${COLORS.bluishBlack};
    }
    .MuiFormControl-root {
        margin 0 6px 0;
    }
  }
`;

export default {
  StyledContainer,
};
