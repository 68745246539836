import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledCard = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.grey6};
  padding: 2rem 2rem;
  margin-bottom: 2rem;

  .mb-3 {
    color: ${COLORS.grey};
    font-size: 12px;
  }

  &:hover .MuiIconButton-root {
    display: block;
  }
`;

const StyledCardHeader = styled.div`
  h6 {
    margin-bottom: 0;
    a {
      font-weight: 700;
    }
  }
  .MuiIconButton-root {
    display: none;
    padding: 0;
    &:hover {
      background-color: initial;
    }
  }
`;

const StyledCardContent = styled.div`
  margin-bottom: 1rem;
  .card-info {
    font-size: 12px;
    color: ${COLORS.grey};
    span {
      margin-right: 10px;
    }
  }
`;

const StyledCardActions = styled.div`
  .round-box {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    border: 1px solid ${COLORS.lightGrey};
    margin-bottom: 10px;
    color: ${COLORS.bluishBlack};
    text-align: center;
  }
  button {
    height: 35px;
  }
`;

export default {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
  StyledCardActions,
};
