import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";

import AppContext from "services/context";
import UserService from "services/logic/userService";
import GlobalDataService from "services/logic/globalDataService";
import dataProvider from "services/api/dataProvider";

export default function ContextProvider({ children }) {
  const localUser = useMemo(() => UserService.getUser() || {}, []);
  const localGlobalData = useMemo(() => GlobalDataService.getData() || {}, []);
  const [user, setUser] = useState(localUser);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [globalData, setGlobalData] = useState(localGlobalData);

  useQuery("globalData", () =>
    dataProvider
      .getList("management/bank")
      .then((res) => {
        if (!res.data) {
          return {};
        }
        GlobalDataService.setData(res.data);
        importScript(res.data?.chatbot_script);
        setGlobalData(res.data);
        return res.data;
      })
      .catch((err) => err)
  );

  return (
    <AppContext.Provider
      value={{ user, setUser, globalData, currencyRates, setCurrencyRates }}
    >
      {children}
    </AppContext.Provider>
  );
}

function importScript(scriptToImport) {
  if (!scriptToImport) {
    return;
  }
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.innerHTML = scriptToImport;
  document.body.appendChild(script);
}
