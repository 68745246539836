import axios from "axios";
import HttpStatus from "http-status-codes";

import { history } from "../../routing/history";
import { IDENTITY_URL } from "../../utils/constants";
import UserService from "./userService";

class AuthService {
  static refreshing = false;
  static subscribers = [];

  static onAccessTokenFetched(accessToken) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    this.subscribers.forEach((callback) => callback(accessToken));
    this.subscribers = [];
  }

  static isTokenExpired(error = {}) {
    return error.status === HttpStatus.UNAUTHORIZED;
  }

  static async getToken() {
    const refreshAxios = axios.create();
    const tempRefrToken = UserService.getRefreshToken();

    const url = `${IDENTITY_URL}/users/token/refresh`;
    const data = JSON.stringify({ refreshToken: tempRefrToken });
    const headers = { "Content-Type": "application/json" };

    const refreshResponse = await refreshAxios.request({
      method: "post",
      url,
      data,
      headers
    });

    return refreshResponse;
  }

  static async refreshAuth(error = {}) {
    try {
      const { response: errorResponse } = error;
      const resetToken = UserService.getRefreshToken();

      if (!resetToken) {
        // We can't refresh, throw the error anyway
        return error.response;
      }
      const retryOriginalRequest = new Promise((resolve) => {
        /* We need to add the request retry to the queue
          since there another request that already attempt to
          refresh the token */
        this.subscribers.push((accessToken) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });

      if (!this.refreshing) {
        this.refreshing = true;
        const refreshResponse = await this.getToken();

        if (!refreshResponse.data) {
          this.subscribers = [];
          this.refreshing = false;
          UserService.removeUser();
          history.push("/");
          return error.response;
        }

        UserService.setAccessToken(refreshResponse.data.token);
        UserService.setRefreshToken(refreshResponse.data.refreshToken);
        this.refreshing = false;

        this.onAccessTokenFetched(refreshResponse.data.token);
      }
      return retryOriginalRequest;
    } catch (err) {
      this.subscribers = [];
      this.refreshing = false;
      UserService.removeUser();
      history.push("/");
      return error.response;
    }
  }
}

export default AuthService;
