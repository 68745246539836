import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import AppContext from "services/context";
import authProvider from "services/api/authProvider";
import { Button, Heading, Icon } from "components/ui";
import Tabs from "components/app/Tabs";

import PhoneIcon from "assets/icons/phone.svg";

import Styles from "../styles/HerounitStyle";

export default function Herounit() {
  const history = useHistory();
  const loggedIn = authProvider.checkAuth();
  const { globalData = {} } = useContext(AppContext);

  const sliderData =
    globalData.homepage_slider && typeof globalData.homepage_slider === "string"
      ? JSON.parse(globalData.homepage_slider)
      : globalData.homepage_slider || [];

  return (
    <Styles.StyledWrapper>
      <Styles.StyledCarousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        autoPlay
      >
        {sliderData.map((slide, index) => (
          <Styles.StyledSlide key={index}>
            <img src={slide.picture} />
            <Styles.StyledInfo>
              <Heading level="1">{slide.text}</Heading>
              <Heading level="6">
                <div>
                  <Icon src={PhoneIcon} /> (001) 555 345 876
                </div>
              </Heading>
            </Styles.StyledInfo>
          </Styles.StyledSlide>
        ))}
      </Styles.StyledCarousel>
      <Styles.StyledFixedContainer fixed>
        <Styles.StyledForms xs="auto">
          <Styles.StyledFormContainer>
            {loggedIn ? (
              <Styles.StyledInfoBox>
                <h5>Customer Dashboard</h5>
                <Button onClick={() => history.push("/dashboard")}>Open</Button>
              </Styles.StyledInfoBox>
            ) : (
              <Tabs
                tabLabels={["Login", "Registration"]}
                tabPanels={[
                  <Styles.StyledLoginForm />,
                  <Styles.StyledRegistrationForm />,
                ]}
              />
            )}
          </Styles.StyledFormContainer>
        </Styles.StyledForms>
      </Styles.StyledFixedContainer>
    </Styles.StyledWrapper>
  );
}
