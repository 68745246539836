import React, { useState } from "react";
import styled from "styled-components";
import { Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import { COLORS } from "utils/constants";

export default function useMenu(options = [], value, menuOptions = {}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (onClick) => {
    onClick?.();
    handleClose();
  };

  return {
    menu: (
      <StyledMenu
        anchorEl={anchorEl}
        //keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        {...menuOptions}
      >
        {options.map((option, index) => {
          const { label, onClick, icon, ...rest } = option;
          return (
            <MenuItem
              key={index}
              selected={option.name === value}
              onClick={() => handleOptionClick(onClick)}
              {...rest}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    ),
    openMenu: handleClick,
  };
}

const StyledMenu = styled(Menu)`
  & .MuiMenuItem-root {
    color: ${COLORS.grey};
    &:hover {
      background-color: ${COLORS.lightBlue};
    }
    &:last-child {
      color: ${COLORS.dangerRed};
    }
  }
  & .MuiListItemIcon-root {
    min-width: 30px;
    position: relative;
    top: -2px;
  }
`;
