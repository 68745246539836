import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Layout from "components/app/Layout";
import Herounit from "./components/Herounit";
import OurServices from "./components/OurServices";
import LoanCalculator from "./components/LoanCalculator";
import LatestNews from "./components/LatestNews";
import Indexes from "./components/Indexes";

import Styles from "./HomePageStyle";

export default function HomePage() {
  return (
    <Layout isHome>
      <Herounit />
      <Styles.StyledContainer>
        <OurServices />
        <LoanCalculator />
        <LatestNews />
        <Indexes />
      </Styles.StyledContainer>
    </Layout>
  );
}
