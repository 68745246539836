import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Heading } from "components/ui";

const StyledWrapper = styled.div`
  .big-news-container {
    padding-right: 20px;
    @media only screen and (max-width: 991px) {
      margin-bottom: 40px !important;
      padding-right: 0;
    }
  }
`;

const StyledHeading = styled(Heading)`
  font-weight: 700 !important;
  padding: 2.5rem 0;
  margin-bottom: 0 !important;
`;

export default {
  StyledWrapper,
  StyledHeading,
};
