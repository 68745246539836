import styled from "styled-components";
import { Link } from "react-router-dom";

import { COLORS } from "utils/constants";

const StyledCard = styled.div`
  margin-bottom: 1rem;
  @media only screen and (max-width: 991px) {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledCardContent = styled.div`
  & .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    .MuiTypography-h6 {
      font-weight: 700;
    }
    & time {
      font-size: 0.625rem;
      color: ${COLORS.grey};
      margin-top: -5px;
    }
  }
  & p {
    color: ${COLORS.grey};
    font-size: 0.875rem;
    line-height: 1.313rem;
    
    @media only screen and (max-width: 991px) {
      margin-top: 15px;  
    }
  }
`;

const StyledImageLink = styled(Link)`
  & > img {
    margin-bottom: 1rem;
    border-radius: 10px;
    width: 100%;
    @media only screen and (max-width: 767px) {
      height: 290px;
      object-fit: cover;
    }
  }
  &:hover img {
    opacity: 0.8;
  }
`;

export default {
  StyledCard,
  StyledCardContent,
  StyledImageLink,
};
