import React from "react";
import PropTypes from "prop-types";

import { Heading, Icon } from "components/ui";
import Style from "../styles/BalanceCardStyle";

export default function BalanceCard({ title, balance, imgSrc, link }) {
  return (
    <Style.StyledBoxContainer title={title}>
      <span className="subtitle">Balance</span>
      <Heading level="4">{balance}</Heading>
      <div className="icon-container">
        <Icon src={imgSrc} />
      </div>
      {link}
    </Style.StyledBoxContainer>
  );
}

BalanceCard.propTypes = {
  title: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.element.isRequired,
};
