import axios from "axios";
import UserService from "./logic/userService";
import AuthService from "./logic/authService";
// Guide: https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/

export default async function request(
  url,
  method,
  payload,
  optionalHeaders,
  optionalResponseType
) {
  const headers = {
    "Content-Type": "application/json",
    ...optionalHeaders,
  };
  const responseType = optionalResponseType || "json";
  const axiosConfig = {
    method,
    url,
    data: payload ? payload : null,
    headers,
    responseType,
  };
  // Get user data from local storage
  const user = await UserService.getUser();

  /**
   * Create instance of Axios
   * Pass headers before request through interceptor
   * Handle errors after server response. Refresh token if it`s expired
   */
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use((reqWithUser) => {
    if (user) {
      reqWithUser.headers.Authorization = `Bearer ${user.accessToken}`;
    }
    Object.keys(headers).forEach((key) => {
      reqWithUser.headers[key] = headers[key];
    });
    return reqWithUser;
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      // If the request succeeds, we don't have to do anything and just return the response
      response,
    (error) => {
      const { response } = error;
      const resError = response?.data?.message
        ? response.data.message
        : response;
      if (AuthService.isTokenExpired(response)) {
        return AuthService.refreshAuth(error);
      }
      // If the error is due to other reasons, we just throw it back to axios
      return response;
    }
  );

  const responsePromise = await axiosInstance.request(axiosConfig);
  return responsePromise;
}
