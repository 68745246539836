//import UIPage from "pages/UIPage";
import NoMatch from "pages/no-match/NoMatch";
import LandingPage from "pages/landing/LandingPage";
import HomePage from "pages/home/HomePage";
import DashboardPage from "pages/dashboard/DashboardPage";
import AccountsPage from "pages/accounts/AccountsPage";
import AccountTransactionsPage from "pages/accounts/TransactionsPage";
import TransferPage from "pages/transfer/TransferPage";
import LoansPage from "pages/loans/LoansPage";
import CardsPage from "pages/cards/CardsPage";
import CardsTransactionsPage from "pages/cards/TransactionsPage";
import ForecastsPage from "pages/forecasts/ForecastsPage";
import StocksPage from "pages/stocks/StoksPage";
import StocksTransactions from "pages/stocks/TransactionsPage";
import StockDetails from "pages/stocks/StockDetails";
import UnderConstructionPage from "pages/under-construction/UnderConstructionPage";

export default [
  {
    path: "/",
    breadcrumb: "Home",
    component: HomePage,
    isProtected: false,
    isExact: true,
    redirect: false,
  },
  {
    path: "/landing",
    breadcrumb: "Landing page",
    component: LandingPage,
    isProtected: false,
    isExact: true,
    redirect: false,
  },
  // {
  //   path: "/ui",
  //   breadcrumb: "UI elements",
  //   component: UIPage,
  //   isProtected: false,
  //   isExact: true,
  // },
  {
    path: "/dashboard",
    breadcrumb: "Dashboard",
    component: DashboardPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/accounts",
    breadcrumb: "Accounts",
    component: AccountsPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/accounts/transactions",
    breadcrumb: "Transactions",
    component: AccountTransactionsPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/new-transfer",
    breadcrumb: "New Transfer",
    component: TransferPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/loans",
    breadcrumb: "Loans",
    component: LoansPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/cards",
    breadcrumb: "Cards",
    component: CardsPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/cards/transactions",
    breadcrumb: "Transactions",
    component: CardsTransactionsPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/forecasts",
    breadcrumb: "Forecasts",
    component: ForecastsPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/stocks",
    breadcrumb: "Stocks",
    component: StocksPage,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/stocks/transactions",
    breadcrumb: "Transactions",
    component: StocksTransactions,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/stocks/:abr",
    breadcrumb: undefined,
    component: StockDetails,
    isProtected: true,
    isExact: true,
    redirect: false,
  },
  {
    path: "/under-construction",
    breadcrumb: "Under Construction",
    component: UnderConstructionPage,
    isProtected: false,
    isExact: true,
    redirect: false,
  },
  {
    path: "*",
    breadcrumb: "No Match",
    component: NoMatch,
    isProtected: false,
    isExact: false,
    redirect: false,
  },
];
