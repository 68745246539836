import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import styled from "styled-components";

import { COLORS } from "utils/constants";

export default function CardComponent({ title, children }) {
  return (
    <StyledCard>
      <CardHeader title={title} />
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  &.MuiPaper-root {
    box-shadow: initial;
    border: 2px solid ${COLORS.whitishBlue};
    border-radius: 10px;
  }
  & .MuiCardHeader-root {
    padding: 1.3rem 1.2rem;
    background-color: ${COLORS.whitishBlue};
    & .MuiTypography-h5 {
      font-size: 14px;
      color: ${COLORS.bluishBlack};
    }
  }
  & .MuiCardContent-root {
    padding: 0 !important;
  }
  & .MuiTypography-h5 {
    font-weight: 700;
  }
`;

CardComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};
CardComponent.defaultProps = {
  className: "",
  children: null,
};
