import React, { useState, useMemo, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import FileSaver from "file-saver";

import DemoService from "services/logic/demoService";
import {
  Layout,
  BoxContainer,
  Table,
  TableColumn,
  FilterForm,
  DateCell,
  NumberCell,
  ExportGroup,
  NoResults,
} from "components/app";
import { formatDisplayNumbers } from "utils/misc";
import { TRANSACTION_TYPE } from "utils/constants";

import dataProvider from "services/api/dataProvider";
import Style from "./TransactionsPageStyle";

const date = moment().format("YYYY-MM-DD");
const fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");

function MemoryLeakDemo() {
  const [memoryLeak, setMemoryLeak] = useState(false);
  useEffect(() => {
    DemoService.simulateSlowNetworkRequest(2000).then(() => {
      setInterval(() => setMemoryLeak(true), 800);
    });
  }, []);

  return null;
}

export default function TransactionsPage() {
  const [account, setAccount] = useState({});
  const [filter, setFilter] = useState({
    iban: null,
    from: fromDate,
    to: date,
    transaction_type: "all",
  });
  const [mounted, setMounted] = useState();

  const { state } = useLocation();

  const initialAcc = useMemo(() => state?.account || {}, [state]);
  const iban =
    initialAcc.iban || initialAcc.account_iban || initialAcc.bankAccount;

  /** Demo generating errors **/
  const { data: demoData = {} } = useQuery(
    "managmentDemo",
    () =>
      dataProvider
        .getList("management/demo")
        .then((res) => res?.data || {})
        .catch((err) => err),
    { cacheTime: 0 }
  );

  useEffect(() => {
    const isMemoryLeak =
      demoData.performance?.find((item) => item?.code === "MEMLEAKUI")?.value ===
        "true" ?? false;

    if (isMemoryLeak) {
      setMounted(isMemoryLeak);
    }
  }, [demoData]);

  useEffect(() => {
    if (mounted === undefined) {
      return;
    }

    if (mounted) {
      setTimeout(() => {
        setMounted(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setMounted(true);
      }, 500);
    }
  }, [mounted]);
  /** End demo generating errors **/
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      iban,
    }));
  }, [iban]);

  useEffect(() => {
    setAccount(initialAcc);
  }, [initialAcc]);

  const {
    data = {},
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["transactions", filter], () =>
    filter.iban
      ? dataProvider
          .getList(`accounts/${filter.iban}/transactions`, {
            query: filter,
          })
          .then((res) => res?.data || {})
          .catch((err) => err)
      : {}
  );
  const transactionsData = data?.data ?? [];

  const exportData = useMutation(({ dataType, ...rest }) =>
    dataProvider
      .getList(`accounts/${filter.iban}/export`, {
        headers: {
          "Content-Type": dataType
            ? dataType === "csv"
              ? "text/csv"
              : "application/pdf"
            : "text/csv",
        },
        query: rest,
        responseType: "blob",
      })
      .then(async (res) => {
        FileSaver.saveAs(
          res.data,
          `transactions_${filter.from}-${filter.to}.${dataType}`
        );
      })
      .catch((err) => err)
  );

  const handleFilterSubmit = (values) => {
    const { account, ...vals } = values;
    setFilter(vals);
    setAccount(account);
    refetch();
  };

  const TableCaption = ({ data = {} }) => {
    return (
      <Style.StyledTableCaption className="table-results">
        <Row>
          <Col sm={6}>
            <Style.StyledCaptionRow>
              <Col>
                <span>Opening Balance</span>
              </Col>
              <Col sm={4} className="text-right">
                <span>{formatDisplayNumbers(data.opening_balance, 2)}</span>
              </Col>
            </Style.StyledCaptionRow>
            <Style.StyledCaptionRow>
              <Col>
                <span>Total Withdrawals for the period</span>
              </Col>
              <Col sm={4} className="text-right">
                <span className="text-danger">
                  {formatDisplayNumbers(data.total_for_period?.debit, 2)}
                </span>
              </Col>
            </Style.StyledCaptionRow>
            <Style.StyledCaptionRow>
              <Col>
                <span>Total Deposits for the period</span>
              </Col>
              <Col sm={4} className="text-right">
                <span className="text-success">
                  {formatDisplayNumbers(data.total_for_period?.credit, 2)}
                </span>
              </Col>
            </Style.StyledCaptionRow>
            <Style.StyledCaptionRow>
              <Col>
                <span>Closing Balance</span>
              </Col>
              <Col sm={4} className="text-right">
                <span>{formatDisplayNumbers(data.closing_balance, 2)}</span>
              </Col>
            </Style.StyledCaptionRow>
          </Col>
        </Row>
      </Style.StyledTableCaption>
    );
  };

  return (
    <Layout>
      {mounted && <MemoryLeakDemo />}
      <Style.StyledBoxContainer title="Review My Transactions">
        <FilterForm
          initialValues={filter}
          isNewTransfer
          isTransactionType
          onSubmit={handleFilterSubmit}
        />
      </Style.StyledBoxContainer>
      <ExportGroup
        onExport={(dataType) => exportData.mutate({ ...filter, dataType })}
        loading={exportData.isLoading}
      />
      {transactionsData.length > 0 || isLoading || isFetching ? (
        <BoxContainer
          title={`Transactions for the period: ${transactionsData.length}`}
        >
          <Table
            data={transactionsData}
            caption={<TableCaption data={data} />}
            loading={isLoading || isFetching}
          >
            <TableColumn
              title="Transaction Date"
              field="transaction_date"
              cell={DateCell}
            />
            <TableColumn
              title="Date Transferred"
              field="value_date"
              cell={DateCell}
            />
            <TableColumn
              title={`Debit ${account.currency_code || account.currency || ""}`}
              field="debit"
              className="text-danger"
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.debit.toLowerCase()
                  ? NumberCell({ item, field })
                  : null
              }
            />
            <TableColumn
              title={`Credit ${
                account.currency_code || account.currency || ""
              }`}
              field="credit"
              className="text-success"
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.credit.toLowerCase()
                  ? NumberCell({ item, field })
                  : null
              }
            />
            <TableColumn title="Reference" field="reference" />
            <TableColumn title="Type" field="transaction_merchant_type" />
            <TableColumn
              title="Beneficiary/Sender"
              field=""
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.debit.toLowerCase()
                  ? item.recipient
                  : item.sender
              }
            />
            <TableColumn title="Details" field="details" />
          </Table>
        </BoxContainer>
      ) : (
        <BoxContainer title="Your search returned no mathces.">
          <NoResults
            period={{ from: filter.from, to: filter.to }}
            account={`${account.name || ""} - ${
              account.currency_code || account.currency || ""
            } - ${account.iban || account.bank_account || ""}`}
          />
        </BoxContainer>
      )}
    </Layout>
  );
}
