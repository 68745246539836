import styled from "styled-components";
import { COLORS } from "utils/constants";
import Table from "components/app/table/Table";
import { Row } from "react-bootstrap";

const StyledRow = styled(Row)`
  .col-sm-8 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-lg-6 {
    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .col-sm-4 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const StyledContainer = styled.div`
  .total-values {
    font-weight: 700;
  }
`;

const StyledGraphContainer = styled.div`
  position: relative;
  padding: 0 2rem;
`;

const StyledTable = styled(Table)`
  padding: 0 2rem 2rem 2rem;
  .legend-color {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 2px;
  }
  .MuiTableCell-root {
    border-bottom: 1px solid ${COLORS.lightGrey4};
    color: ${COLORS.grey7};
  }
  .MuiTable-root caption {
    padding: 0;
    .col {
      padding-top: 18px;
      padding-bottom: 18px;
      border-top: 1px solid ${COLORS.lightGrey4};
    }
    .col:first-of-type,
    .col:last-of-type {
      color: ${COLORS.bluishBlack};
    }
    .col:last-of-type {
      text-align: right;
    }
  }
`;

export default {
  StyledRow,
  StyledContainer,
  StyledGraphContainer,
  StyledTable,
};
