import React, { useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { Col } from "react-bootstrap";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import { formatCurrencyNumbers } from "utils/misc";
import { TRANSACTION_TYPE } from "utils/constants";
import { BoxContainer, TableColumn } from "components/app";
import { Link } from "components/ui";
import { CurrencyCell, DateCell } from "components/app/table/Cell";
import BalanceCard from "./BalanceCard";

import CoinsIcon from "assets/icons/coins.svg";
import Style from "../styles/AccountsSectionStyle";

export default function AccountsSection() {
  const { globalData } = useContext(AppContext);

  const { data: accountsData = [] } = useQuery("accounts", () =>
    dataProvider
      .getList("accounts")
      .then(({ data }) => data?.data ?? [])
      .catch((err) => err)
  );
  const firstAccount = accountsData?.[0];

  const {
    data = {},
    isLoading,
    isFetching,
  } = useQuery("dashboardAccounts", () =>
    dataProvider
      .getList("accounts/dashboard")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  const urlData = useMemo(
    () => ({
      pathname: "/accounts/transactions",
      state: {
        account: firstAccount,
      },
    }),
    [firstAccount]
  );

  return (
    <Style.StyledRow>
      <Col sm="3">
        <BalanceCard
          title="Bank Accounts"
          balance={formatCurrencyNumbers(
            data?.balance_all_accounts,
            globalData?.main_currency
          )}
          imgSrc={CoinsIcon}
          link={
            <Link icon to="/accounts">
              View All Accounts
            </Link>
          }
        />
      </Col>
      <Col sm="9">
        <BoxContainer title="Accounts: Last 3 transactions">
          <Style.StyledTable
            data={data?.last_three_transactions ?? []}
            loading={isLoading || isFetching}
          >
            <TableColumn
              title="Date"
              field="transaction_date"
              cell={DateCell}
            />
            <TableColumn
              title="Debit"
              field="debit"
              className="text-danger"
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.debit.toLowerCase()
                  ? CurrencyCell({ item, field }, item.currency_code)
                  : null
              }
              align="right"
            />
            <TableColumn
              title="Credit"
              field="credit"
              className="text-success"
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.credit.toLowerCase()
                  ? CurrencyCell({ item, field }, item.currency_code)
                  : null
              }
              align="right"
            />
            <TableColumn title="Type" field="transaction_merchant_type" />
            <TableColumn
              title="Beneficiary/Sender"
              field=""
              cell={({ item, field }) =>
                item.transaction_type === TRANSACTION_TYPE.debit.toLowerCase()
                  ? item.recipient
                  : item.sender
              }
            />
            <TableColumn
              title="Account"
              field="account_iban"
              cell={LinkCell}
              align="left"
            />
          </Style.StyledTable>
          <Link to={urlData} icon className="link">
            View All Transactions
          </Link>
        </BoxContainer>
      </Col>
    </Style.StyledRow>
  );
}

/**
 * Table custom cells
 */
const LinkCell = ({ item, field }) => {
  const urlData = {
    pathname: "/accounts/transactions",
    state: {
      [field]: item[field],
      account: item,
    },
  };

  return <Link to={urlData}>{item[field]}</Link>;
};
