import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";
import { gapi } from "gapi-script";
import { GOOGLE_CLIENT_ID } from "../../../utils/constants";
import auth from "services/api/authProvider";
import UserService from "services/logic/userService";
import AppContext, { TabsContext } from "services/context";
import { ErrorMessage, TextInput } from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";
import { Modal } from "components/app";
import { GoogleLogin } from "react-google-login";
import jwtDecode from "jwt-decode";

import Styles from "../styles/LoginFormStyle";

const schema = yup.object().shape({
  username: yup.string().required(VALIDATION_TEXTS.required).trim(),
  password: yup.string().required(VALIDATION_TEXTS.required).trim(),
});

export default function LoginForm({ className }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState();
  const [forgotPassStep, setForgotPassStep] = useState(1);
  const [forgottenUsername, setForgottenUsername] = useState("");
  const { setTabIndex } = useContext(TabsContext);
  const { setUser } = useContext(AppContext);
  const history = useHistory();

  const handleLoginUser = async ({ values }) => {
    const res = await auth.login(values);
    if (res.status === StatusCodes.OK) {
      const user = UserService.getUser();
      setUser(user);
      history.push("/dashboard");
    } else {
      setError(res?.data);
    }
  };
  const checkUser = useMutation(handleLoginUser);

  const handleForgottenPassSubmit = (username) => {
    setForgottenUsername(username);
    setForgotPassStep(2);
    // GA-event
    ReactGA.event({
      category: "Users",
      action: "password_forgot",
      label: username,
    });
  };
  //Comment to check commit
  const handleSubmit = (values) => {
    setError();
    checkUser.mutate({ values });
    //checkUser.reset();
    // GA-event
    ReactGA.event({
      category: "Users",
      action: "login",
    });
  };

  const handleCreateAccount = () => {
    setModalOpen(false);
    // switch to registration tab
    setTabIndex && setTabIndex(1);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const handleResetPass = (pass) => {
    const values = {
      username: forgottenUsername,
      password: pass,
    };

    setModalOpen(false);
    handleLoginUser({ values });
    // GA-event
    ReactGA.event({
      category: "Users",
      action: "new_password",
      label: forgottenUsername,
    });
  };

  const handleForgottenPass = () => {
    setModalOpen(true);
    // GA-event
    ReactGA.event({
      category: "Users",
      action: "forgotten_password_open",
    });
  };

  const responseGoogle = async response => {
    const resJson = response;
    const accessToken = response.tokenId;
    const res = await auth.googleLogin(accessToken);
    if (res.status === StatusCodes.OK) {
      const user = UserService.getUser();
      setUser(user);
      history.push("/dashboard");
    } else {
      setError(res?.data);
    }

    // const decoded = jwtDecode(accessToken);
    //
    // UserService.setUser({
    //   accessToken,
    //   // refreshToken,
    //   ...decoded,
    // });
    // history.push("/dashboard");

  };
  const responseGoogleError = (response) => {
    console.log(response);
  };
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);
  return (
      <>
        <Form noValidate onSubmit={formik.handleSubmit} className={className} data-testid="LoginForm">
          <TextInput
              id="form-username"
              label="User Name"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.username}
              round
              darker
          />
          <TextInput
              id="form-password"
              type="password"
              label="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.password}
              round
              darker
          />
          <Styles.StyledLinkButton
              type="button"
              component="button"
              variant="body2"
              onClick={handleForgottenPass}
              underline="none"
          >
            Forgot password?
          </Styles.StyledLinkButton>
          {error?.message && <ErrorMessage error={error.message} />}
          <div>
            <Styles.StyledSubmitButton
                type="submit"
                loading={checkUser.isLoading}
            >
              Sign-In
            </Styles.StyledSubmitButton>
          </div>
          <div>
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={"single_host_origin"}
            />
          </div>
        </Form>

        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={
              forgotPassStep === 1 ? "Forgot your password?" : "Create new password"
            }
            onExited={() => setForgotPassStep(1)}
        >
          {forgotPassStep === 1 && (
              <Styles.StyledForgottenPassForm
                  onCreateAccountClick={handleCreateAccount}
                  onSubmit={({ username }) => handleForgottenPassSubmit(username)}
              />
          )}
          {forgotPassStep === 2 && (
              <Styles.StyledNewPassForm
                  username={forgottenUsername}
                  onSubmit={handleResetPass}
              />
          )}
        </Modal>
      </>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
};
LoginForm.defaultProps = {
  className: "",
};
