import React, { useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import { VALIDATION_TEXTS } from "utils/constants";
import {
  formatDisplayNumbers,
  optionsFromData,
  convertCurrencyRates,
} from "utils/misc";
import {
  Button,
  ErrorMessage,
  TextInput,
  SelectInput,
  Heading,
} from "components/ui";

import Styles from "../styles/BuySellStockFormStyle";

const schema = yup.object().shape({
  fromAccount: yup.string().required(VALIDATION_TEXTS.required),
  amount: yup
    .number()
    .positive()
    .required(VALIDATION_TEXTS.required)
    .max(yup.ref("balance"), `${VALIDATION_TEXTS.less_ammount} Balance`),
  currencyCode: yup.string().required(VALIDATION_TEXTS.required),
});

export default function BuyStockForm({ data, onCancel, onReload }) {
  const [error, setError] = useState();

  const { currencyRates } = useContext(AppContext);

  // Get accounts data
  const { data: accountsData = {} } = useQuery("accounts", () =>
    dataProvider
      .getList("accounts")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );
  const accountData = useMemo(() => accountsData?.data ?? [], [accountsData]);
  // Exclude accounts with balance <= 0
  const filteredAccounts = useMemo(
    () => accountData.filter((acc) => acc.balance > 0) || [],
    [accountData]
  );

  // First account object from filtered data
  const initialAccount = useMemo(
    () => filteredAccounts[0] || {},
    [filteredAccounts]
  );

  const handleBuyStock = async (values) => {
    // For the amount of stock value, assign the converted amount in currency instead of the fixed USD,
    // in case it uses account different from USD
    formik.values.amount = -formatDisplayNumbers(
        formik.values.amount *
        convertCurrencyRates(
            formik.values.currencyCode,
            accountFromIban(formik.values.fromAccount).currency_code,
            currencyRates
        )
    )

    const res = await dataProvider.create("stocks/portfolio/buy", values);
    if (res.status === StatusCodes.CREATED) {
      onReload();
      onCancel();
    } else {
      typeof res === "string" ? setError(res) : setError(res?.data);
    }
  };
  const buyStock = useMutation(handleBuyStock);

  const handleSubmit = (values) => {
    buyStock.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: "product_bought",
      label: values.stockAbr,
    });
  };

  const formik = useFormik({
    initialValues: {
      stockAbr: data.abbreviation || "",
      fromAccount: initialAccount.iban || "",
      balance: initialAccount.balance || "",
      amount: "",
      currencyCode: "USD",
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleAccountChange = (event) => {
    const newAcc =
      filteredAccounts.find((item) => item.iban === event.target.value) || {};

    formik.handleChange(event);
    formik.setFieldValue("balance", newAcc.balance || "");
  };

  const accountFromIban = (iban) => {
    const acc = filteredAccounts.find((item) => item.iban === iban) || {};
    return acc;
  };

  return (
    <Styles.StyledForm noValidate onSubmit={formik.handleSubmit}>
      <Styles.StyledStockInfo>
        <div>
          <Heading level="6">
            {data.abbreviation || data.stock_abbreviation}
          </Heading>
          <div className="small-text">{data.name || data.stock_name}</div>
        </div>
        <div>
          <div className="small-text">Current Buy Price</div>
          <Heading level="4" className="text-success">
            <>
              {formatDisplayNumbers(
                data.buyPrice || data.currentPrice?.buyValue
              )}{" "}
              USD
            </>
          </Heading>
        </div>
      </Styles.StyledStockInfo>
      <Form.Row>
        <Col sm={9}>
          <SelectInput
            id="form-from_account"
            name="fromAccount"
            label="From Account"
            value={formik.values.fromAccount}
            options={optionsFromData(filteredAccounts, "iban", [
              "name",
              "currency_code",
              "iban",
            ])}
            onChange={handleAccountChange}
            required
          />
          <ErrorMessage error={formik.errors.fromAccount} />
        </Col>
        <Col sm={3}>
          <TextInput
            id="form-balance"
            name="balance"
            label="Balance"
            value={
              formatDisplayNumbers(formik.values.balance, 2) +
              " " +
              accountFromIban(formik.values.fromAccount).currency_code
            }
            //onChange={formik.handleChange}
            readOnly
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          type="number"
          id="form-amount"
          name="amount"
          label="Amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
          required
          error={formik.errors.amount}
        />
        <TextInput
          groupProps={{ as: Col, md: "3" }}
          id="form-currencyCode"
          name="currencyCode"
          label="Currency"
          value={formik.values.currencyCode}
          //onChange={formik.handleChange}
          readOnly
        />
      </Form.Row>
      {accountFromIban(formik.values.fromAccount).currency_code !==
        formik.values.currencyCode && (
        <Styles.StyledDetails>
          <p>
            Amount in {accountFromIban(formik.values.fromAccount).currency_code}
            :{" "}
            <span>
              {formatDisplayNumbers(
                formik.values.amount *
                  convertCurrencyRates(
                    formik.values.currencyCode,
                    accountFromIban(formik.values.fromAccount).currency_code,
                    currencyRates
                  )
              )}{" "}
              {accountFromIban(formik.values.fromAccount).currency_code}
            </span>
          </p>
          <p>
            Currency Rate{" "}
            <span>
              1 {accountFromIban(formik.values.fromAccount).currency_code} ={" "}
              {convertCurrencyRates(
                accountFromIban(formik.values.fromAccount).currency_code,
                formik.values.currencyCode,
                currencyRates
              )}{" "}
              {formik.values.currencyCode}
            </span>
          </p>
        </Styles.StyledDetails>
      )}
      {error && <ErrorMessage error={error?.message ?? error} />}
      <div className="buttons-group">
        <Button type="submit" loading={buyStock.isLoading}>
          Buy
        </Button>
        <Button variant="secondary" type="button" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Styles.StyledForm>
  );
}

BuyStockForm.propTypes = {
  data: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReload: PropTypes.func,
};
BuyStockForm.defaultProps = {
  onReload: () => null,
};
