import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import {
  formatCurrencyNumbers,
  generateRandomColors,
  formatDisplayNumbers,
  extractValuesForGraph,
} from "utils/misc";
import AppContext from "services/context";
import Calculations from "services/logic/calculationService";
import Period from "./Period";
import DetailsBar from "./DetailsBar";
import {
  Card,
  CurrencyCell,
  TableColumn,
  PercentCell,
  Chart,
  Loader,
} from "components/app";
import Style from "../styles/ExpenseVisualizationStyle";

let randomIncomeColors = [];
let randomExpenseColors = [];

export default function ExpenseVisualization({ data = {}, loading }) {
  const { globalData } = useContext(AppContext);
  const currency = globalData?.main_currency;

  randomIncomeColors = useMemo(
    () =>
      data.aggregated_income_items &&
      randomIncomeColors.length !== data.aggregated_income_items?.length
        ? generateRandomColors(data.aggregated_income_items?.length ?? 0)
        : randomIncomeColors,
    [data.aggregated_income_items]
  );
  randomExpenseColors = useMemo(
    () =>
      data.aggregated_expense_items &&
      randomExpenseColors.length !== data.aggregated_expense_items.length
        ? generateRandomColors(data.aggregated_expense_items?.length ?? 0)
        : randomExpenseColors,
    [data.aggregated_expense_items]
  );

  const chartDataIncome = {
    labels: extractValuesForGraph(data.aggregated_income_items, "description"),
    datasets: [
      {
        label: "Income",
        data: extractValuesForGraph(data.aggregated_income_items, "amount"),
        backgroundColor: randomIncomeColors,
      },
    ],
  };

  const chartDataExpenses = {
    labels: extractValuesForGraph(data.aggregated_expense_items, "description"),
    datasets: [
      {
        label: "Expenses",
        data: extractValuesForGraph(data.aggregated_expense_items, "amount"),
        backgroundColor: randomExpenseColors,
      },
    ],
  };

  const TableCaption = ({ data }) => {
    return (
      <Row noGutters className="total-values">
        <Col>Total</Col>
        <Col>
          {formatCurrencyNumbers(
            Calculations.sumDataValues(data, "amount"),
            currency
          )}
        </Col>
        <Col>
          {formatDisplayNumbers(Calculations.sumDataValues(data, "percent"))}%
        </Col>
      </Row>
    );
  };

  return (
    <Style.StyledContainer>
      <Period />
      <Style.StyledRow>
        <Col sm={8}>
          <Style.StyledRow>
            <Col lg={6}>
              <Card title="Income">
                <Style.StyledGraphContainer>
                  <Chart
                    key={loading}
                    type="doughnut"
                    data={chartDataIncome}
                    loading={loading}
                  />
                  {loading && <Loader over />}
                </Style.StyledGraphContainer>
                <Style.StyledTable
                  data={data.aggregated_income_items}
                  noGutters
                  striped={false}
                  caption={<TableCaption data={data.aggregated_income_items} />}
                >
                  <TableColumn
                    title="Description"
                    field="description"
                    cell={(props) => DescriptionCell(props, randomIncomeColors)}
                    align="left"
                    width="40%"
                  />
                  <TableColumn
                    title="Amount"
                    field="amount"
                    cell={({ item, field }) =>
                      CurrencyCell({ item, field }, currency)
                    }
                    align="center"
                    width="30%"
                  />
                  <TableColumn
                    title="Percent"
                    field="percent"
                    cell={PercentCell}
                    align="right"
                    width="30%"
                  />
                </Style.StyledTable>
              </Card>
            </Col>
            <Col lg={6}>
              <Card title="Expenses">
                <Style.StyledGraphContainer>
                  <Chart
                    key={loading}
                    type="doughnut"
                    data={chartDataExpenses}
                    loading={loading}
                  />
                  {loading && <Loader over />}
                </Style.StyledGraphContainer>
                <Style.StyledTable
                  data={data.aggregated_expense_items}
                  noGutters
                  striped={false}
                  caption={
                    <TableCaption data={data.aggregated_expense_items} />
                  }
                >
                  <TableColumn
                    title="Description"
                    field="description"
                    cell={(props) =>
                      DescriptionCell(props, randomExpenseColors)
                    }
                    align="left"
                    width="40%"
                  />
                  <TableColumn
                    title="Amount"
                    field="amount"
                    cell={({ item, field }) =>
                      CurrencyCell({ item, field }, currency)
                    }
                    align="center"
                    width="30%"
                  />
                  <TableColumn
                    title="Percent"
                    field="percent"
                    cell={PercentCell}
                    align="right"
                    width="30%"
                  />
                </Style.StyledTable>
              </Card>
            </Col>
          </Style.StyledRow>
        </Col>
        <Col sm={4}>
          <DetailsBar data={data} isExpense />
        </Col>
      </Style.StyledRow>
    </Style.StyledContainer>
  );
}

const DescriptionCell = ({ item, field, rowIndex }, colors) => (
  <>
    <span
      className="legend-color"
      style={{
        backgroundColor: colors?.[rowIndex],
      }}
    />
    {item[field]}
  </>
);

ExpenseVisualization.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
