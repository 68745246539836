import styled from "styled-components";
import { Form } from "react-bootstrap";

import { COLORS } from "utils/constants";

const StyledForm = styled(Form)`
  .form-group {
    margin-bottom: 1.25rem;
  }
  > label {
    margin: 1.5rem 0 2rem;
    font-weight: 700;
    font-size: 1.2rem;
  }
  .form-label {
    font-size: 0.75rem;
    margin-bottom: 10px;
    &.big-label {
      color: ${COLORS.black} !important;
      font-size: 1rem;
      margin-bottom: 40px;
    }
  }
  .MuiInputLabel-formControl {
    top: -10px;
  }
  .radio-buttons-group {
    margin-bottom: 40px;
  }
  .form-check-inline {
    margin-right: 20px;
    .form-check-input {
      margin-right: 10px;
    }
  }
  > .buttons-group {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
  button:first-of-type {
    margin-right: 20px;
  }
`;

const StyledDetails = styled.div`
  margin: 1rem 0 2rem;
  & span {
    color: ${COLORS.bluishBlack};
    display: inline-block;
    font-weight: 700;
    margin-left: 10px;
  }
  & p {
    color: ${COLORS.grey};
    font-size: 0.75rem;
    font-weight: 500;
  }
`;

const StyledStockInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  > div {
    padding-right: 40px;
    margin-right: 40px;
    &:first-of-type {
      border-right: 1px solid ${COLORS.lightGrey};
    }
    > h4 {
      margin-bottom: 0;
    }
    > .small-text {
      color: ${COLORS.grey};
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export default { StyledForm, StyledDetails, StyledStockInfo };
