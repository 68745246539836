import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledContainer = styled.div``;

const StyledPortfolioContainer = styled.div`
  margin-bottom: 3rem;
  h4 {
    font-weight: 700;
    span {
      font-weight: 400;
    }
  }
  a {
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyledGraphContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.grey6};
  padding: 1rem;
  margin-bottom: 2.2rem;
  &.p-lg {
    padding: 2.5rem;
  }
`;

const StyledLegendContainer = styled.div`
  display: flex;
  font-size: 12px;
  > div:not(:last-of-type) {
    margin: 0 1.25rem 1rem 0;
  }
  .legend-color {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 2px;
  }
`;

export default {
  StyledContainer,
  StyledPortfolioContainer,
  StyledGraphContainer,
  StyledLegendContainer,
};
