import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
//import moment from "moment";

import { extractLabelsForGraph, extractValuesForGraph } from "utils/misc";
import Period from "./Period";
import DetailsBar from "./DetailsBar";
import Style from "../styles/ForecastsVisualizationStyle";
import { COLORS } from "utils/constants";
import { Chart, Loader } from "components/app";

export default function ForecastsVisualization({ data = {}, loading }) {
  const chartOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "month",
            displayFormats: {
              month: "MMM YYYY",
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            precision: 0,
          },
        },
      ],
    },
    elements: {
      line: {
        tension: 0,
      },
    },
  };

  const chartData = {
    labels: extractLabelsForGraph(
      [data.graph_balance, data.graph_expense, data.graph_income],
      "year_month"
    ),
    datasets: [
      {
        label: "balance",
        data: extractValuesForGraph(data.graph_balance, "year_month", "amount"),
        fill: false,
        borderWidth: 2,
        borderColor: COLORS.bluishBlack,
        pointRadius: 0,
        pointHoverRadius: 9,
        pointHoverBorderWidth: 8,
        pointHoverBorderColor: COLORS.white,
        pointBackgroundColor: COLORS.purple,
      },
      {
        label: "expense",
        data: extractValuesForGraph(data.graph_expense, "year_month", "amount"),
        fill: false,
        borderWidth: 2,
        borderColor: COLORS.dangerRed,
        pointRadius: 0,
        pointHoverRadius: 9,
        pointHoverBorderWidth: 8,
        pointHoverBorderColor: COLORS.white,
        pointBackgroundColor: COLORS.purple,
      },
      {
        label: "income",
        data: extractValuesForGraph(data.graph_income, "year_month", "amount"),
        fill: false,
        borderWidth: 2,
        borderColor: COLORS.successGreen,
        pointRadius: 0,
        pointHoverRadius: 9,
        pointHoverBorderWidth: 8,
        pointHoverBorderColor: COLORS.white,
        pointBackgroundColor: COLORS.purple,
      },
    ],
  };

  return (
    <Style.StyledContainer>
      <Period />
      <Row>
        <Col sm={8}>
          <Style.StyledGraphContainer>
            <Chart
              type="line"
              data={chartData}
              chartOptions={chartOptions}
              loading={loading}
            />
            {loading && <Loader over />}
          </Style.StyledGraphContainer>
        </Col>
        <Col sm={4}>
          <DetailsBar data={data} />
        </Col>
      </Row>
    </Style.StyledContainer>
  );
}

ForecastsVisualization.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
