import {
  API_URL,
  IDENTITY_URL,
  // GRANT_TYPE,
  // CLIENT_ID,
  // CLIENT_SECRET,
  // SCOPE,
} from "utils/constants";
import jwtDecode from "jwt-decode";
import HttpStatus from "http-status-codes";
import request from "../request";
import UserService from "../logic/userService";

const authProvider = {
  login: async ({ username, password }) => {
    const body = JSON.stringify({ username, password });
    const res = await request(`${API_URL}/users/login`, "post", body, {});

    if (res.status === HttpStatus.OK) {
      const resJson = res.data;
      const accessToken = resJson.token;
      const refreshToken = resJson.refreshToken;
      const decoded = jwtDecode(accessToken);

      UserService.setUser({
        accessToken,
        refreshToken,
        ...decoded,
      });
    }

    return res;
  },
  googleLogin: async (accessToken) => {
    const res = await request(`${API_URL}/users/login`, "post", {}, {
      'google-access-token': accessToken
    });

    if (res.status === HttpStatus.OK) {
      const resJson = res.data;
      const accessToken = resJson.token;
      const refreshToken = resJson.refreshToken;
      const decoded = jwtDecode(accessToken);

      UserService.setUser({
        accessToken,
        refreshToken,
        ...decoded,
      });
    }

    return res;
  },
  logout: () => {
    UserService.removeUser();
  },
  checkAuth: () => !!UserService.getUser(),
  getPermissions: () => {
    const role = UserService.getPermissions();
    return role;
  },
};

export default authProvider;
