import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Tabs, Tab } from "@material-ui/core";

import { TabsContext } from "services/context";
import { COLORS } from "utils/constants";

function TabPanel({ children, value, index, ...rest }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
}

export default function TabsComponent({
  initialTabIndex,
  tabLabels = [],
  tabPanels = [],
  onChange,
  divider,
  className,
  ...rest
}) {
  const [tabIndex, setTabIndex] = useState(initialTabIndex || 0);
  const handleTabChange = (event, newVal) => {
    onChange(newVal);
    setTabIndex(newVal);
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={className}
        divider={divider}
        {...rest}
      >
        {tabLabels.map((tabLabel, index) => (
          <Tab key={index} label={tabLabel} disableRipple />
        ))}
      </StyledTabs>
      <TabsContext.Provider value={{ setTabIndex }}>
        {tabPanels.map((tabPanel, index) => (
          <TabPanel key={index} value={tabIndex} index={index}>
            {tabPanel}
          </TabPanel>
        ))}
      </TabsContext.Provider>
    </>
  );
}

const StyledTabs = styled(({ divider, onTabIndex, ...rest }) => (
  <Tabs {...rest} />
))`
  margin-bottom: 2rem;
  & .MuiTab-root {
    min-width: initial;
    max-width: initial;
    padding: 0 !important;
    margin-right: 30px;
    overflow: visible;
    &:last-child {
      margin-right: 0;
    }
      ${(props) =>
        props.divider &&
        `
          &:not(:last-of-type):after {
            content: "|";
            position: absolute;
            right: -17px;
            color: ${COLORS.grey}
          }
        `}
      }
    }
  }
`;

TabsComponent.propTypes = {
  initialTabIndex: PropTypes.number,
  tabLabels: PropTypes.array.isRequired,
  tabPanels: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  divider: PropTypes.bool,
  className: PropTypes.string,
};
TabsComponent.defaultProps = {
  initialTabIndex: 0,
  onChange: () => null,
  divider: false,
  className: "",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
