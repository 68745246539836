import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import { AutocompleteInput, Button, Icon, SelectMenu } from "components/ui";
import StocksContext from "../stocksContext";

import Style from "../styles/ActionsBarStyle";
import ReloadIcon from "assets/icons/reload.svg";
import GridIcon from "assets/icons/grid.svg";
import ListIcon from "assets/icons/list.svg";

export default function WatchlistActionsBar({
  optionsData,
  isGrid,
  onViewChange,
}) {
  const [watchlist, setWatchlist] = useState();
  const [rerender, setRerender] = useState(false);

  const { options, onReload, watchlistPeriod, setWatchlistPeriod } =
    useContext(StocksContext);

  const handleAddToWatchlist = async ({ abbreviation }) => {
    const res = await dataProvider.create(`stocks/watchlist`, {
      values: {
        stockAbr: abbreviation,
      },
    });

    if (res.status === StatusCodes.CREATED) {
      onReload();
    }
  };

  const addWatchlist = useMutation(handleAddToWatchlist);

  const handleSubmit = () => {
    setRerender((prevState) => !prevState);
    addWatchlist.mutate(watchlist);
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: "watchlist_new_product",
      label: watchlist.abbreviation,
    });
  };

  const handlePeriodChange = (e) => {
    setWatchlistPeriod(e.target.value);
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: "watchlist_period",
      label: e.target.value,
    });
  };

  return (
    <Style.StyledContainer>
      <Style.StyledForm>
        <AutocompleteInput
          key={rerender}
          id="watchlist"
          name="watchlist"
          label="Add to my watchlist"
          options={optionsData}
          optionLabelItem={["abbreviation", "name"]}
          onChange={(e, values) => setWatchlist(values)}
          placeholder="Example: Tesla, Apple"
          //   multiple
          filterSelectedOptions
        />
        <Style.StyledButton
          onClick={handleSubmit}
          autoWidth
          disabled={!watchlist}
          loading={addWatchlist.isLoading}
        >
          Add
        </Style.StyledButton>
      </Style.StyledForm>
      <Style.StyledActions>
        <Button variant="link" onClick={onReload} autoWidth>
          <Icon src={ReloadIcon} />
        </Button>
        <Button variant="link" onClick={onViewChange} autoWidth>
          <Icon src={isGrid ? ListIcon : GridIcon} />
        </Button>
        <SelectMenu
          name="period"
          value={watchlistPeriod}
          options={options}
          onChange={handlePeriodChange}
        />
      </Style.StyledActions>
    </Style.StyledContainer>
  );
}

WatchlistActionsBar.propTypes = {
  optionsData: PropTypes.array.isRequired,
  isGrid: PropTypes.bool.isRequired,
  onViewChange: PropTypes.func.isRequired,
};
