import styled from "styled-components";

import { BoxContainer } from "components/app";
import { COLORS } from "utils/constants";

const StyledContainer = styled(BoxContainer)`
  .card-head {
    @media only screen and (max-width: 480px) {
      display: block;
      .card-title {
        margin-bottom: 1rem;
      }     
    }
  }
  .card-additional-title {
    color: ${COLORS.bluishBlack};
    font-size: 12px;
    margin-top: 5px;
    opacity: 0.7;
    b {
      margin-right: 8px;
      text-transform: uppercase;
    }
  }
  .card-content {
    .MuiList-root {
      margin-bottom: 2.5rem;
    }
    .MuiListItem-root {
      border-bottom: 1px solid ${COLORS.lightGrey3};
      &.accented {
        color ${COLORS.bluishBlack};
      }
      color: ${COLORS.grey};
      .text-lg {
        font-size: 1.2rem;
        margin-left: 20px;
      }
    }
    .MuiListItemText-root:last-of-type {
      text-align: right;
    }
    a {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

export default { StyledContainer };
